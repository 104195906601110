import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import styles from './styles/ComparsionSelectionDialog.styles';
import LeftArrowIcon from '../../../assets/svgs/Icons/LeftArrowIcon';
import ComparsionSectionList from './ComparsionSectIonList';
import { getLabelComparision, getSignedLabelURL } from '../../../api/pages/Reg360';
import getComparisonFile from '../../../helpers/draftableUtils';
import ReportingDrawer from '../../Report/ReportingDrawer';
import DraftableComparisonView from '../DraftableComparisonView';
import { ComparsionSelectionDialogProps } from './type';

const ComparsionSelectionDialog: React.FC<ComparsionSelectionDialogProps> = ({
  open,
  source,
  comparsionType,
  onClose,
  title = 'Enhanced Comparison',
  compareList = [],
  showBackButton = false,
  onBack = undefined
}) => {
  const [viewURL, setViewerURL] = useState<string>('');
  const [childDocument, setChildDocument] = useState<any>({});
  const [parentDocument, setParentDocument] = useState<any>({});
  const [openReporting, setOpenReporting] = React.useState<boolean>(false);
  const handleParentSelect = (item: any) => {
    setParentDocument(item);
  };
  const handleChildSelect = (item: any) => {
    setChildDocument(item);
  };

  const handleSignedURL = useCallback(async (payload: any) => {
    const res: any = await getSignedLabelURL({
      source,
      s3_path: payload?.s3_path,
      center: payload?.center || '',
      is_section: false
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  const handleComparison = async () => {
    if (parentDocument?.s3_path && childDocument?.s3_path) {
      const pdfData = {
        parent_object: await handleSignedURL(parentDocument),
        child_object: await handleSignedURL(childDocument)
      };
      setParentDocument({ ...parentDocument, pdfLink: pdfData?.parent_object });
      setChildDocument({ ...childDocument, pdfLink: pdfData?.child_object });
      const res = await getLabelComparision(pdfData);
      if (res.data.success !== null) {
        setViewerURL(res?.data?.success);
      }
    }
  };

  const downloadFile = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleSwapLabels = () => {
    setParentDocument(childDocument);
    setChildDocument(parentDocument);
  };

  if (openReporting) {
    return (
      <ReportingDrawer
        isDrawerOpen={openReporting}
        closeDrawer={() => {
          setOpenReporting(false);
        }}
        isNavBar
      />
    );
  }

  return (
    <Drawer
      open={open}
      anchor='bottom'
      onClose={onClose}
      PaperProps={{
        style: styles.drwerContainer
      }}>
      <Stack direction='row' sx={styles.headerWrapper}>
        {viewURL && (
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
            onClick={() => {
              setViewerURL('');
              setParentDocument({});
              setChildDocument({});
            }}
            sx={styles.changeLabelsWrapper}>
            <LeftArrowIcon sx={styles.leftArrowIcon} />
            <Typography sx={styles.changeLabelsText}>Change Document</Typography>
          </Stack>
        )}
        {showBackButton && !viewURL && onBack && (
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
            onClick={onBack}
            sx={styles.changeProductsWrapper}>
            <LeftArrowIcon sx={styles.leftArrowIcon} />
            <Typography sx={styles.changeLabelsText}>Change Selection</Typography>
          </Stack>
        )}
        <Stack spacing={1} sx={styles.enhancedComparisonWrapper}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            {title}
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton
            aria-label='delete'
            onClick={onClose}
            size='small'
            sx={styles.closeIconButton}>
            <HighlightOffOutlinedIcon fontSize='inherit' sx={styles.closeIcon} />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent sx={{ p: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              {viewURL ? (
                <DraftableComparisonView
                  viewerURL={viewURL}
                  downloadFile={downloadFile}
                  parentPdfLink={parentDocument?.pdfLink}
                  childPdfLink={childDocument?.pdfLink}
                  parentApplicationNumber={parentDocument?.title}
                  childApplicationNumber={childDocument?.title}
                  parentApplicationName={parentDocument?.title}
                  childApplicationName={childDocument?.title}
                  setOpenReporting={setOpenReporting}
                  enableChatRia
                  handleSwapLabels={handleSwapLabels}
                  comparsionType={comparsionType}
                  parentApplication={{}}
                  childApplication={{}}
                />
              ) : (
                <Box sx={styles.listGridWrapper}>
                  <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={6} height='100%' maxWidth='620px !important'>
                      <ComparsionSectionList
                        data={compareList[0]}
                        onSelectDocument={handleParentSelect}
                        selectedDocument={parentDocument}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      alignItems='flex-start'
                      height='100%'
                      maxWidth='620px !important'>
                      <ComparsionSectionList
                        data={compareList[1]}
                        onSelectDocument={handleChildSelect}
                        selectedDocument={childDocument}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {!viewURL && (
        <DialogActions sx={styles.dialogActionsContainer}>
          <Stack>
            <Button
              onClick={handleComparison}
              disabled={!(parentDocument?.s3_path && childDocument?.s3_path)}
              sx={styles.compareButton}>
              Compare
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Drawer>
  );
};

export default React.memo(ComparsionSelectionDialog);
