const styles = {
  drwerContainer: {
    height: '98vh',
    width: '100vw',
    backgroundColor: '#fff',
    maxWidth: 'none',
    maxHeight: 'none',
    borderRadius: '8px 8px 0px 0px'
  },
  headerWrapper: {
    bgcolor: 'primary.600',
    alignSelf: 'stretch',
    p: 1.5,
    alignItems: 'center'
  },
  changeLabelsWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    left: '2%'
  },
  leftArrowIcon: { height: 14, width: 14, color: 'white.main' },
  changeLabelsText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    color: 'white.main',
    fontWeight: 600
  },
  changeProductsWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    left: '2%'
  },
  enhancedComparisonWrapper: { flexGrow: 1, textAlign: 'center', alignSelf: 'center' },
  closeIconButton: { position: 'absolute', display: 'flex', left: '97%', p: 0 },
  closeIcon: { height: 24, width: 24, color: 'white.main' },
  listGridWrapper: {
    height: '83vh',
    display: 'flex',
    maxHeight: '83vh',
    pt: 2.5,
    pr: 3,
    pb: 0,
    pl: 3
  },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    bgcolor: 'gray.200',
    py: 1.5
  },
  compareButton: {
    height: 38,
    py: 1.25,
    px: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: 'primary.650',
    color: 'white.main',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'white.main',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      color: 'primary.600'
    },
    '&:disabled': {
      backgroundColor: 'gray.550',
      color: 'gray.background'
    }
  }
};

export default styles;
