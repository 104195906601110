import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// styles
import styles from '../styles/AllUpdates.styles';
import getDateString from '../../../utils/getDateString';
import AllUpdatesDrawer from './AllUpdatesDialog';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center'>
        {data && (
          <Stack direction='column'>
            <Typography variant='subtitle2' sx={styles.dateText}>
              {getDateString(data?.updated_date)}
            </Typography>
            {data.change_history_available && (
              <Typography
                variant='subtitle2'
                sx={styles.allUpdates}
                onClick={e => handleOpenPopup(e)}>
                All Updates
              </Typography>
            )}
          </Stack>
        )}
      </Box>
      {open && (
        <AllUpdatesDrawer
          openDrawer={open}
          onClose={() => setOpen(false)}
          // eslint-disable-next-line no-underscore-dangle
          guidanceId={data.guidance_id}
          title={data.title}
        />
      )}
    </Box>
  );
};

const AllUpdates = (params: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  return <GridCellDocuments data={params.row} />;
};

export default AllUpdates;
