import themev5 from '../../../themev5';

const styles = {
  root: {
    height: '90vh',
    background: '#F7F7F7',
    pl: '108px',
    width: `100%`
  },
  tabContainer: {
    display: 'flex',
    paddingRight: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.lightBorder',
    background: '#F7F7F7',
    alignItems: 'flex-end'
  },
  tabs: {
    paddingLeft: 1,
    marginBottom: '-1px'
  },
  activeTab: {
    border: '0.5px solid',
    borderColor: 'gray.light',
    borderRadius: '10px 10px 0px 0px',
    fontSize: '16px',
    boxShadow: 'none',
    color: 'gray.main',
    textTransform: 'capitalize'
  },
  inactiveTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: 'white.main',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    background: 'gradient.main'
  },
  indicator: {
    background: 'primary.background'
  },
  heading: {
    fontSize: 1,
    background: '#F7F7F7'
  },
  filterTextContainer: {
    margin: '8px',
    cursor: 'pointer',
    marginRight: 48
  },
  filterText: {
    marginLeft: '3px',
    marginRight: '3px',
    fontSize: '0.8rem',
    color: '#6D7D8A'
  },
  airplaneIcon: {
    height: '52px',
    width: '52px',
    position: 'fixed',
    zIndex: themev5.zIndex.drawer + 2,
    bottom: themev5.spacing(2),
    left: themev5.spacing(2)
  },
  comparisonIcon: { height: 16, width: 16, color: 'primary.700' },
  comparisonButton: {
    fontSize: 14,
    textTransform: 'none',
    fontWeight: '400',
    ml: 0.5,
    color: 'primary.700'
  },
  divider: { height: 16, borderColor: 'gray.300' },
  popOver: {
    '& .MuiPopover-paper': {
      borderRadius: '16px !important',
      backgroundColor: 'none'
    }
  }
};

export default styles;
