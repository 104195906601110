// Function to transform and rename the keys using COLUMN_NAME_MAPPING
const transformMHRAGuidanceData = (data: any, columnMapping: any) => {
  const allowedColumns = Object.keys(columnMapping);
  return data?.map((each: any) =>
    allowedColumns.reduce((acc: any, key: any) => {
      if (each[key] !== undefined) {
        if (Array.isArray(each[key])) {
          acc[columnMapping[key]] = each[key].join(', ');
        } else {
          acc[columnMapping[key]] = each[key];
        }
      }
      return acc;
    }, {})
  );
};

export default transformMHRAGuidanceData;
