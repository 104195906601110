import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Virtuoso } from 'react-virtuoso';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { filterSidebarOptionsStyles, styles } from './styles';
import { FilterSidebarOptionsProps } from './types';
import { isObject } from './utils';
import MultiSearchKeyword from './MultiSearchKeyword';
import { FILTER_TYPE } from './constants';
import VirtuosoCustomScrollbar from '../VirtuosoCustomScrollbar/VirtuosoCustomScrollbar';
import CustomCheckbox from '../CustomComponents/Checkbox';
import { ArrowDropDownIcon } from '../../assets/svgs/Icons';

const FilterSidebarOptions = ({
  setShowFilterSidebarOptions,
  selectedCategory,
  setSelectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions
}: FilterSidebarOptionsProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [options, setOptions] = useState<Array<string>>([]);
  const [subCategories, setSubCategories] = useState<Record<string, string[]>>({});

  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  useEffect(() => {
    setOptions(selectedCategory.options);
    setSearchText('');
    const container: any = document.getElementById('filter-sidebar-options-content-container');
    if (container) {
      container.scrollTop = 0;
    }
  }, [selectedCategory]);

  const handleChange = (ev: any) => {
    const searchedText = ev.target.value.toLowerCase();
    setSearchText(ev.target.value);

    const formattedOptions = selectedCategory.options.filter((option: any) => {
      // Start by creating a list of lowercase strings to compare against `searchText`
      let selectedOptionStrings: string[] = [];

      // If `option` is an object, grab `label` and possibly `subtopics`
      if (isObject(option)) {
        if (option?.subtopics) {
          selectedOptionStrings = [
            option.label.toLowerCase(),
            ...option.subtopics.map((subtopic: string) => subtopic.toLowerCase())
          ];
        } else {
          // Make sure to correct the `.toLowerCase()` typo
          selectedOptionStrings = [option.label.toLowerCase()];
        }
      } else {
        // If option is just a string, push it as a single-element array
        selectedOptionStrings = [option.toLowerCase()];
      }

      // Check if any of the strings in selectedOptionStrings includes `searchText`
      return selectedOptionStrings.some(str => str.includes(searchedText));
    });

    setOptions(formattedOptions);
  };

  const handleCheckbox = (value: any) => {
    const subtopics = value?.subtopics;
    const hasSubtopicsArray = Array.isArray(subtopics);
    const currentCategoryOptions = selectedCategoryOptions[selectedCategoryValueKey];
    const totalSelectedKeys = Object.keys(selectedCategoryOptions).length;

    // If value has subtopics as an array, handle it as a "grouped" checkbox
    if (subtopics && hasSubtopicsArray) {
      const isSubTopicLabelAlreadySelected = currentCategoryOptions?.some(
        (each: { label: string; subtopics: string[] }) => each?.label === value?.label
      );
      // If there's already an entry for the current category key, remove it; otherwise, add it
      if (isSubTopicLabelAlreadySelected) {
        const remainingTopics = currentCategoryOptions?.filter(
          (each: { label: string; subtopics: string[] }) => each?.label !== value?.label
        );
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: remainingTopics
        });
      } else {
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: [
            ...(selectedCategoryOptions[selectedCategoryValueKey] ?? []),
            { label: value?.label, subtopics }
          ]
        });
      }
      return;
    }

    const updatedValue = isObject(value) ? value?.label : value;
    // For a "single" checkbox (no subtopics)
    const valueExists =
      currentCategoryOptions?.includes(updatedValue) ||
      currentCategoryOptions
        ?.map((item: any) => item.toLowerCase())
        ?.includes(updatedValue?.toLowerCase());

    // If value already exists for this category, remove it; otherwise, add it
    if (totalSelectedKeys > 0 && valueExists) {
      const filteredOptions = currentCategoryOptions.filter(
        (option: any) => option.toLowerCase() !== updatedValue?.toLowerCase()
      );

      // If there are still other values left after removal, update them; otherwise, remove the key
      if (filteredOptions.length) {
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: filteredOptions
        });
      } else {
        const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
        setSelectedCategoryOptions(others);
      }
    } else {
      // Add value under this category key (append if it already exists, otherwise create a new array)
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]:
          totalSelectedKeys > 0 && currentCategoryOptions
            ? [...currentCategoryOptions, updatedValue]
            : [updatedValue]
      });
    }
  };

  const isSubCategoryChecked = (
    selectedCategoryOptionLabel: string,
    selectedCategoryOptionSubLabel: string
  ) => {
    // 1. Check if there are any selected options at all
    if (Object.keys(selectedCategoryOptions).length === 0) {
      return false;
    }

    // 2. Get the list of topics (if any) for the current category key
    const currentCategoryOptions = selectedCategoryOptions[selectedCategoryValueKey];
    if (!currentCategoryOptions) {
      return false;
    }

    // 3. Find the matching topic by label
    const matchingTopic = currentCategoryOptions.find(
      (topic: Record<string, any>) =>
        topic.label?.toLowerCase() === selectedCategoryOptionLabel?.toLowerCase()
    );
    if (!matchingTopic?.subtopics) {
      return false;
    }

    // 4. Compare subtopics ignoring case
    const subtopicsInLowerCase = matchingTopic.subtopics.map((subTopic: string) =>
      subTopic?.toLowerCase()
    );

    return subtopicsInLowerCase.includes(selectedCategoryOptionSubLabel?.toLowerCase());
  };

  const handleSubCategoryCheckbox = (
    selectedCategoryOptionLabel: string,
    selectedCategoryOptionSubLabel: string
  ) => {
    const currentCategoryOptions = selectedCategoryOptions[selectedCategoryValueKey] || [];

    // Find the category by label (case-insensitive)
    const formattedCurrentCategoryOptions = currentCategoryOptions.find(
      (topic: { label: string; subtopics: string[] }) =>
        topic?.label?.toLowerCase() === selectedCategoryOptionLabel?.toLowerCase()
    );

    // Filter out the subtopic that matches the selected subtopic (case-insensitive)
    const remainingSubtopics =
      formattedCurrentCategoryOptions?.subtopics?.filter(
        (subTopic: string) =>
          subTopic?.toLowerCase() !== selectedCategoryOptionSubLabel?.toLowerCase()
      ) || [];

    // Remove the category that matches the selected label (case-insensitive)
    const remainingCategories = currentCategoryOptions.filter(
      (topic: { label: string; subtopics: string[] }) =>
        topic?.label?.toLowerCase() !== selectedCategoryOptionLabel?.toLowerCase()
    );

    // Check if the subcategory is already selected
    const subCategoryChecked = isSubCategoryChecked(
      selectedCategoryOptionLabel,
      selectedCategoryOptionSubLabel
    );

    if (subCategoryChecked) {
      if (remainingSubtopics.length > 0) {
        // Update the category with remaining subtopics
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: [
            ...remainingCategories,
            {
              label: selectedCategoryOptionLabel,
              subtopics: remainingSubtopics
            }
          ]
        });
      } else if (remainingCategories.length > 0) {
        // Remove the entire category if it has no remaining subtopics
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: remainingCategories
        });
      } else {
        // Remove the category key if no categories remain
        const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
        setSelectedCategoryOptions(others);
      }
    } else {
      // Add the subcategory to the selected options
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: [
          ...remainingCategories,
          {
            label: selectedCategoryOptionLabel,
            subtopics: [...remainingSubtopics, selectedCategoryOptionSubLabel]
          }
        ]
      });
    }
  };

  const handleSelectAll = () => {
    const formattedOptions = options.map((each: any) =>
      isObject(each) && !each?.subtopics ? each?.label : each
    );
    const isAllCategoryOptionsSelected =
      Object.keys(selectedCategoryOptions)?.length > 0 &&
      selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length;
    if (!isAllCategoryOptionsSelected) {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: formattedOptions
      });
    } else {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    }
  };

  const handleReset = () => {
    if (selectedCategory.filterType === FILTER_TYPE.MULTI_SEARCH) {
      const valuesToRemove = selectedCategory.options.map((option: any) => option.value);
      // removing keys present in valuesToRemove variable from selectedCategoryOptions
      const { ...result } = selectedCategoryOptions;
      valuesToRemove.forEach((value: any) => {
        delete result[value];
      });
      setSelectedCategoryOptions(result);
    } else {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    }
  };

  const isChecked = (each: any) => {
    return (
      Object.keys(selectedCategoryOptions).length > 0 &&
      !!selectedCategoryOptions[selectedCategoryValueKey] &&
      selectedCategoryOptions[selectedCategoryValueKey]
        .map((item: any) => (isObject(item) ? item?.label?.toLowerCase() : item?.toLowerCase()))
        .includes(isObject(each) ? each.label?.toLowerCase() : each?.toLowerCase())
    );
  };

  const isCategoryCheckboxIntermediate = (checkedData: { label: string; subtopics: string[] }) => {
    if (checkedData?.subtopics && Array.isArray(checkedData?.subtopics)) {
      const selectedSubTopicCategory =
        !!selectedCategoryOptions[selectedCategoryValueKey] &&
        selectedCategoryOptions[selectedCategoryValueKey]?.find(
          (each: { label: string; subtopics: string[] }) => each?.label === checkedData?.label
        );

      return (
        selectedSubTopicCategory?.subtopics &&
        selectedSubTopicCategory?.subtopics?.length > 0 &&
        selectedSubTopicCategory?.subtopics?.length !== checkedData?.subtopics?.length
      );
    }
    return false;
  };

  const handleDropDownClick = (subCategoryLabel: string, subCategoryValue: string[]) => {
    if (
      Object.keys(subCategories)?.length > 0 &&
      Object.keys(subCategories)?.includes(subCategoryLabel)
    ) {
      const { [subCategoryLabel]: _, ...others } = subCategories;
      setSubCategories(others);
    } else {
      setSubCategories({ ...subCategories, [subCategoryLabel]: subCategoryValue });
    }
  };

  const labelText = (each: any) => {
    // If 'each' isn't an object, just return it as-is.
    if (!isObject(each)) {
      return each;
    }

    // If 'each' is an object and has a 'count', format "label (count)".
    // Otherwise, return just "label".
    return each.count ? `${each.label} (${each.count})` : each.label;
  };

  return (
    <Box sx={filterSidebarOptionsStyles.container}>
      <Box height={60} sx={styles.sidebarHeaderFooter}>
        <Typography fontSize={20} color='gray.800' fontWeight={700} ml={4}>
          {selectedCategory.label}
        </Typography>
        <Button
          onClick={() => {
            setShowFilterSidebarOptions();
            setSelectedCategory();
          }}
          sx={{ ...styles.closeIconButton, mr: 4 }}>
          <CloseIcon sx={styles.icon} />
        </Button>
      </Box>
      <Divider />
      {selectedCategory.filterType === FILTER_TYPE.SELECT_OPTIONS && (
        <>
          <TextField
            placeholder={`Search ${selectedCategory.label}`}
            onChange={handleChange}
            value={searchText}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon sx={styles.icon} />
                </InputAdornment>
              ),
              sx: { borderRadius: 2, fontSize: 14, width: 242, height: 44 }
            }}
            sx={filterSidebarOptionsStyles.search}
          />
          <Box display='flex' justifyContent='flex-end'>
            {options?.length > 0 && (
              <Button
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: 'none',
                  textDecorationLine: 'underline',
                  color: 'primary.600',
                  mr: 2.5
                }}
                onClick={handleSelectAll}>
                {Object.keys(selectedCategoryOptions)?.length > 0 &&
                selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length
                  ? 'Deselect all'
                  : 'Select all'}
              </Button>
            )}
          </Box>
        </>
      )}
      <Box
        sx={{
          ...styles.scrollContainer,
          ...filterSidebarOptionsStyles.contentContainer
        }}
        id='filter-sidebar-options-content-container'>
        {selectedCategory.filterType === FILTER_TYPE.SELECT_OPTIONS ? (
          <Virtuoso
            components={{
              Scroller: VirtuosoCustomScrollbar
            }}
            overscan={800}
            totalCount={options?.length ?? 0}
            data={options}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(_index, each: any) => (
              <React.Fragment key={isObject(each) ? each.label : each}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignContent='center'
                  py={0.75}
                  mb={
                    Object.keys(subCategories)?.length > 0 &&
                    Object.keys(subCategories)?.includes(each?.label)
                      ? 0
                      : 1
                  }>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={isChecked(each)}
                        value={isChecked(each)}
                        indeterminate={isCategoryCheckboxIntermediate(each)}
                        onChange={() => {
                          handleCheckbox(each);
                        }}
                        size='small'
                        style={{
                          color: isChecked(each) ? '#0D917D' : '#424242'
                        }}
                        sx={{ p: 0, mr: 1.5 }}
                      />
                    }
                    label={labelText(each)}
                    sx={filterSidebarOptionsStyles.contentText}
                  />
                  {isObject(each) && each?.subtopics && (
                    <Icon
                      sx={{ mr: 1.5, p: 0, color: 'gray.600', fontSize: 16, alignItems: 'center' }}
                      onClick={() => handleDropDownClick(each?.label, each?.subtopics)}>
                      <ArrowDropDownIcon
                        sx={{
                          cursor: 'pointer',
                          transform:
                            Object.keys(subCategories)?.length > 0 &&
                            Object.keys(subCategories)?.includes(each?.label)
                              ? 'rotate(180deg)'
                              : '',
                          fontSize: 10
                        }}
                      />
                    </Icon>
                  )}
                </Box>
                {Object.keys(subCategories)?.length > 0 &&
                  Object.keys(subCategories)?.includes(each?.label) && (
                    <Box display='flex' pl={5} pt={0.75} pb={1}>
                      <Divider sx={{ border: '1px solid', borderColor: 'gray.300' }} />
                      <Box display='flex' flexDirection='column'>
                        {each?.subtopics?.map((topic: string) => (
                          <Box
                            key={topic}
                            display='flex'
                            alignItems='center'
                            py={0.75}
                            pl={2.2}
                            pr={4}>
                            <CustomCheckbox
                              checked={isSubCategoryChecked(each?.label, topic)}
                              value={isSubCategoryChecked(each?.label, topic)}
                              onChange={() => {
                                handleSubCategoryCheckbox(each?.label, topic);
                              }}
                              size='small'
                              style={{
                                color: isSubCategoryChecked(each?.label, topic)
                                  ? '#0D917D'
                                  : '#424242'
                              }}
                              sx={{ p: 0, pr: 1.5 }}
                            />
                            <Tooltip title={topic}>
                              <Typography sx={styles.threeLineText} fontSize={14} color='gray.800'>
                                {topic}
                              </Typography>
                            </Tooltip>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
              </React.Fragment>
            )}
          />
        ) : (
          <Box m={2.5} ml={3}>
            <MultiSearchKeyword
              selectedCategoryOptions={selectedCategoryOptions}
              setSelectedCategoryOptions={setSelectedCategoryOptions}
              selectedCategory={selectedCategory}
            />
          </Box>
        )}
      </Box>
      <Divider />
      <Box minHeight={60} sx={styles.sidebarHeaderFooter}>
        <Button
          disabled={Object.keys(selectedCategoryOptions).length === 0}
          size='small'
          sx={{ ...styles.clearAll, ml: 3 }}
          onClick={handleReset}>
          Clear
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(FilterSidebarOptions);
