/* eslint-disable import/prefer-default-export */
import makeRequest, { makeRequestWithCancel } from '../client';

export const fetchPDCOMeetings = (filters: any, cancelToken: any) =>
  makeRequestWithCancel(`/pdco/meetings`, 'POST', { filters }, {}, cancelToken);

export const fetchPDCOMeetingDetails = (meetingId: string, filters: any, cancelToken: any = null) =>
  makeRequestWithCancel(`/pdco/meetings/${meetingId}`, 'POST', { filters }, {}, cancelToken);

export const fetchPDCOPIPDetails = (filters: any) =>
  makeRequest(`/pdco/get_data`, 'POST', { filters });
