import { GridColDef } from '@mui/x-data-grid-pro';
import renderTwoLineCellExpand from '../../components/Datagrid/DataGridTwoLineText';

import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { Filter } from '../../components/Filters';
import DocumentHistoryPDF from './components/DocumentHistoryPDF';
import { FILTER_TYPE } from '../../components/NewFilters/constants';
import getDateString from '../../utils/getDateString';
import getTopicsString from './utils/getTopicsString';
import renderListCellExpand from '../../components/Datagrid/DatagridUnorderListPopover';
import AllUpdates from './components/AllUpdatesCell';
import sortDateComparator from '../../utils/sortDateComparator';

export const SEARCH_IGNORE_FIELDS = ['document_history', '_id'];

export interface CandadaGuidancesFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

type CustomGridColDef = GridColDef & {
  capitalCasing?: boolean;
  tagField?: string;
  skipTag?: string[];
};

export const MHRA_GUIDANCE_COLUMN: CustomGridColDef[] = [
  {
    field: 'document_history',
    headerName: 'Document(s)',
    width: 100,
    filterable: false,
    capitalCasing: false,
    headerClassName: 'table-header',
    disableExport: true,
    sortable: false,
    renderCell: DocumentHistoryPDF
  },
  {
    field: 'title',
    tagField: 'guidance_type',
    skipTag: ['Guidance'],
    headerName: 'Title',
    flex: 1,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderTwoLineCellExpand,
    valueGetter: (params: any) => params.value.replace(/ \([\s\S]*?\)/g, '')
  },
  {
    field: 'description',
    headerName: 'About',
    flex: 1,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderTwoLineCellExpand
  },
  {
    field: 'topics',
    headerName: 'Topic(s) / Subtopic(s)',
    width: 250,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderListCellExpand,
    valueGetter: (params: any) =>
      params.value && params.value?.length > 0 ? getTopicsString(params.value) : '-'
  },
  {
    field: 'published_date',
    headerName: 'Published Date',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => (params.value ? getDateString(params.value) : '-'),
    sortComparator: sortDateComparator
  },
  {
    field: 'updated_date',
    headerName: 'Last Updated',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: AllUpdates,
    sortComparator: sortDateComparator,
    valueGetter: (params: any) => (params.value ? getDateString(params.value) : '-')
  }
];

export const MHRA_GUIDANCE_FILTERS = [
  {
    id: 1,
    label: 'Title',
    value: 'title',
    elasticSearchType: 'title',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Topic(s) / Subtopic(s)',
    value: 'topics',
    elasticSearchType: 'topics',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Published Date',
    value: 'published_date',
    elasticSearchType: 'published_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Last Updated',
    value: 'updated_date',
    elasticSearchType: 'updated_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;

export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'mhra_guidances',
  postfix: date,
  additional: '',
  extension: ''
};

export const ENTITY_MAPPING: { [key: string]: any } = {
  description: { name: 'About' },
  published_date: { name: 'Published Date ' },
  updated_date: { name: 'Last Updated' }
};

export const MHRA_GUIDANCE_PIE_CHART = [
  {
    label: 'Topics',
    apiKey: 'topics',
    graphItem: 'topics',
    count: 5
  },
  {
    label: 'Subtopics',
    apiKey: 'subtopics',
    graphItem: 'subtopics',
    count: 5
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 25
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const MHRA_GUIDANCE_REPORT_QUERIES = [
  {
    id: 'guidance_documents_by_year',
    title: {
      title: 'MHRA Guideline Documents by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      },
      axisBottom: {
        tickRotation: 80
      }
    }
  },
  {
    id: 'guidance-by-adopted-date',
    title: {
      title: 'Guidance Documents by Published Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  }
];

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const MHRA_GUIDANCE_COLUMN_NAME_MAPPING = {
  title: 'Title',
  description: 'About',
  published_date: 'Published Date',
  date_adopted: 'Published Date',
  updated_date: 'Updated Date'
};

export default MHRA_GUIDANCE_FILTERS;
