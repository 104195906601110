const styles = {
  datagridCellWrapper: {
    alignItems: 'center',
    lineHeight: '24px',
    width: 1,
    height: 1,
    position: 'relative',
    display: 'flex'
  },
  cellDiv: {
    height: 1,
    display: 'block',
    position: 'absolute',
    top: 0
  },
  paper: {
    maxHeight: 300,
    overflowY: 'auto',
    borderRadius: '8px',
    p: 2,
    pl: 2.5,
    pb: 0,
    minWidth: 206,
    maxWidth: 400
  }
};

export default styles;
