import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
// third party
import { isEmpty, debounce, throttle, capitalize } from 'lodash';
// mui
import { Grid, Stack } from '@mui/material';
// component
import SectionFlyout from './SectionFlyout';
import Timeline from './Timeline';
import MeetingDetailsLoader from '../../EMAMeetings/components/common/MeetingDetailsLoader';
import EmptyMeetingDetails from '../../EMAMeetings/components/EmptyMeetingDetails';
import MeetingDetailsCard from './MeetingDetailsCard';
import PDFViewerModal from '../../EMAMeetings/components/common/PDFViewerModal';
import DocumentListing from '../../../components/Dialogs/DocumentListing';
import { DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';

// styles
import styles from '../../EMAMeetings/styles/Meeting.styles';
import MeetingHeader from '../../EMAMeetings/components/common/MeetingHeader';
import MeetingNavigation from '../../EMAMeetings/components/common/MeetingNavigation';

const Meetings = ({
  allMeetingsByYear,
  selectedMeeting,
  handleSelectMeeting,
  meetingListLoading,
  meetingDetailsLoading,
  resources,
  meetingType,
  handleSelectMeetingType
}: {
  allMeetingsByYear: any;
  selectedMeeting: any;
  handleSelectMeeting: any;
  meetingListLoading: boolean;
  meetingDetailsLoading: boolean;
  resources: any;
  meetingType: any;
  handleSelectMeetingType: any;
}) => {
  const containerRef = useRef(null);
  const [timelineDetails, setTimelineDetails] = useState<any>({});
  const [activeSection, setActiveSection] = useState('');
  const [showSection, setShowSection] = useState(true);
  const [resourceDialog, setResourceDialog] = useState<boolean>(false);
  const [showTimeline, setShowTimeline] = useState<boolean>(false);
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [documentCategory, setDocumentCategory] = useState<string>('');
  const [PDFData, setPDFData] = useState<string>('');
  const scrollStatus = useRef<boolean>(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // throttle in order to reduce the stutter while scrolling
    const throttledSetActiveSection = throttle(sectionId => {
      setActiveSection(sectionId);
    }, 300);

    // handle intersections when scrolling manually
    const handleIntersectionEntries = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          throttledSetActiveSection(entry.target.id);
        }
      });
    };

    // handle intersection to avoid when user click on any section
    const handleEmptyIntersection = debounce(() => {
      scrollStatus.current = false;
    }, 200);

    const handleIntersection = (entries: any) => {
      if (scrollStatus.current) {
        handleEmptyIntersection();
      } else {
        handleIntersectionEntries(entries);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: container,
      threshold: 0.1
    });

    const sectionsId = Object.keys(selectedMeeting?.sections);

    if (sectionsId?.length > 0) {
      sectionsId.forEach((section: any) => {
        const element = document.getElementById(section);
        if (element) observer.observe(element);
      });
    } else {
      setActiveSection('');
    }

    // eslint-disable-next-line consistent-return
    return () => {
      sectionsId.forEach(section => {
        const element = document.getElementById(section);
        if (element) observer.unobserve(element);
      });
    };
  }, [meetingDetailsLoading]);

  const handleSectionClick = (id: string) => {
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveSection(id);
      // ref is used in order for not triggering scroll intersection function
      scrollStatus.current = true;
    }
  };

  const getSelectedMeetingType = () => {
    const meeting = allMeetingsByYear[selectedMeeting?.meeting_stats?.meeting_year]?.find(
      (each: any) => each.date === selectedMeeting?.meeting_stats?.meeting_date
    );
    if (meeting?.category_bucket === 'minute') {
      return 'minutes';
    }
    return meeting?.category_bucket ?? 'minutes';
  };

  const handlePDFOpenClick = useCallback((pdfLink: string, name: string, category: string) => {
    setTitle(name);
    setDocumentCategory(category);
    setOpenPdf(true);
    setPDFData(pdfLink);
  }, []);

  const handleClosePDF = useCallback(() => {
    setOpenPdf(false);
    setPDFData('');
    setDocumentCategory('');
  }, []);

  const documents: DocumentsGroup[] = useMemo(() => {
    const categoryMap: Record<string, string> = {
      agenda: 'Agenda',
      minute: 'Minutes'
    };

    const groups: DocumentsGroup[] = [];

    Object.keys(resources || {}).forEach(key => {
      // eslint-disable-next-line camelcase, no-shadow
      const docs = resources[key]?.map(({ title, s3_url }: any) => ({
        title,
        // eslint-disable-next-line camelcase
        title_link: s3_url,
        chatRiaMetadata: {
          'original-file-name': title,
          'meeting-year': selectedMeeting?.meeting_stats?.meeting_year,
          'category-bucket': categoryMap[key] || 'Others'
        }
      }));

      if (docs?.length) {
        groups.push({
          id: key,
          categoryTitle: categoryMap[key] || 'Others',
          documents: docs
        });
      }
    });

    return groups;
  }, [selectedMeeting?.meeting_stats?.meeting_id, resources]);

  const chatRIAMetadata = useMemo(() => {
    return {
      'original-file-name': title,
      'meeting-year': selectedMeeting?.meeting_stats?.meeting_id,
      'category-bucket': capitalize(documentCategory)
    };
  }, [selectedMeeting?.meeting_stats?.meeting_id, resources]);

  return (
    <Stack direction='column' px={5}>
      <Grid container spacing={2}>
        {/* meeting navigation list */}
        <Grid item xs={2.4} md={2.4} lg={2.5}>
          {/* meeting list */}
          <MeetingNavigation
            meetingType={meetingType}
            handleSelectMeetingType={handleSelectMeetingType}
            allMeetingsByYear={allMeetingsByYear}
            selectedMeetingId={selectedMeeting?.meeting_stats?.meeting_id}
            meetingListLoading={meetingListLoading}
            handleSelectMeeting={handleSelectMeeting}
          />
        </Grid>
        <Grid item xs={9.6} md={9.6} lg={9.5}>
          {/* Meeting details */}
          <Stack spacing={2} sx={styles.meetingListContainer}>
            {!meetingDetailsLoading && !isEmpty(selectedMeeting) && (
              <>
                {/* Header */}
                <MeetingHeader
                  emaMeetingType='PDCO'
                  meetingType={getSelectedMeetingType()}
                  meetingDate={selectedMeeting?.meeting_stats?.meeting_date}
                  meetingYear={selectedMeeting?.meeting_stats?.meeting_year}
                  documentCount={selectedMeeting?.meeting_stats.documents.length}
                  onDocumentClick={() => setResourceDialog(true)}
                />

                {/* section */}
                <Grid
                  container
                  id='meetingSectionsContainer'
                  ref={containerRef}
                  sx={{
                    marginTop: '0px !important'
                  }}>
                  <Grid
                    item
                    sm={showSection ? 8.8 : 11}
                    md={showSection ? 9 : 11}
                    lg={showSection ? 9.4 : 11}
                    id='meetingSections'
                    sx={styles.sectionsScrollContainer}>
                    {Object.keys(selectedMeeting?.sections).length > 0 ? (
                      <MeetingDetailsCard
                        sections={selectedMeeting.sections}
                        setTimelineDetails={setTimelineDetails}
                        setShowTimeline={setShowTimeline}
                      />
                    ) : (
                      <EmptyMeetingDetails
                        resources={resources}
                        handlePDFOpenClick={handlePDFOpenClick}
                      />
                    )}
                  </Grid>

                  <SectionFlyout
                    activeSection={activeSection}
                    showSection={showSection}
                    setShowSection={setShowSection}
                    handleSectionClick={handleSectionClick}
                    selectedMeetingSections={selectedMeeting?.sections}
                  />
                </Grid>
              </>
            )}
            {/* resources */}
            {resourceDialog && (
              <DocumentListing
                defaultDocuments={documents}
                defaultSelectedEntityId=''
                title={selectedMeeting?.meeting_stats?.meeting_date}
                onClose={() => setResourceDialog(false)}
                chatRIAMetadata={chatRIAMetadata}
                source='pdco'
                metadata={[]}
                infoComponent={null}
                disableChatRia={false}
                secondaryDrawerTitle=''
              />
            )}

            <PDFViewerModal
              source='PDCO'
              openPdf={openPdf}
              setOpenPdf={setOpenPdf}
              setResourceDialog={setResourceDialog}
              meetingId={selectedMeeting?.meeting_stats?.meeting_id}
              handleClosePDF={handleClosePDF}
              title={title}
              documentCategory={documentCategory}
              PDFData={PDFData}
              setPDFData={setPDFData}
            />
            {meetingDetailsLoading && <MeetingDetailsLoader />}
          </Stack>
        </Grid>
      </Grid>
      {showTimeline && (
        <Timeline
          timelineDetails={timelineDetails}
          clearTimeline={setShowTimeline}
          selectedMeetingId={selectedMeeting?.meeting_stats?.meeting_id}
        />
      )}
    </Stack>
  );
};

export default React.memo(Meetings);
