import getDateString from '../../../utils/getDateString';

const getComparsionMetadata = (documentList: Array<any>) => {
  if (documentList?.length !== 0) {
    const compareList: Array<any> = [];
    documentList.forEach(doc => {
      const docObject: any = {};
      docObject.title = doc?.title;
      docObject.source = 'mhra';
      docObject.documentList = doc?.documents
        ?.sort((a: any, b: any) => (a?.document_type || '').localeCompare(b?.document_type || ''))
        .map((item: any, index: number) => {
          return {
            metadata: [
              {
                id: index + 1,
                label: 'Document Date',
                value: item?.document_date ? getDateString(item?.document_date) : ''
              },
              {
                id: index + 1,
                label: 'Document Type',
                value: item?.document_type?.replace('(s)', '')
              },
              {
                id: index + 1,
                label: 'Document Type Tag',
                value: item?.document_type_tag
              }
            ].filter(meta => meta?.value),
            s3_path: item?.s3_path,
            title: item?.document_title,
            document_date: item?.document_date,
            id: item?.id
          };
        });
      compareList.push(docObject);
    });

    return compareList;
  }
  return [];
};

export default getComparsionMetadata;
