import { BAR_CHART_COLOR, PIECHARTCOLOR } from '../CustomComponents/Graphs/constant';

export type ChartData = {
  id: string;
  graphType: string;
  title: any;
  keys?: any;
  index?: string;
  query: any;
  graphStyle?: any;
  data: any;
  apikey: string;
  disableReport?: boolean;
  isExpandedButton?: boolean;
  toolTipText?: string;
  config?: any;
};
export type VisualizeChartMappingType = {
  [key: string]: {
    layer1: ChartData[];
    layer2: ChartData[];
  };
};

const VISUALIZE_CHART_MAPPING: VisualizeChartMappingType = {
  'fda-guidance': {
    layer1: [
      {
        id: 'guidance_documents_by_year',
        graphType: 'BAR',
        title: {
          title: 'Guidance Documents by Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: { 'x-axis': ['issue_datetime'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 70, left: 30 }
        },
        data: {},
        apikey: 'issueDateBar'
      }
    ],
    layer2: [
      {
        id: 'document_type',
        graphType: 'PIE',
        title: {
          title: 'Document Type',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['communication_type'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: 'gray.main',
          radialLabelsTextColor: 'gray.main',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'document_type'
      },
      {
        id: 'products',
        graphType: 'PIE',
        title: {
          title: 'Products',
          show: true
        },
        query: {
          'x-axis': ['regulated_product_field'],
          agg_function: 'count',
          limit: 6
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'products'
      },
      {
        id: 'fda_organization',
        graphType: 'PIE',
        title: {
          title: 'FDA Organization',
          show: true
        },
        query: {
          'x-axis': ['center'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'fda_organization'
      }
    ]
  },
  'ema-guidance': {
    layer1: [
      {
        id: 'guidance_documents_by_year',
        graphType: 'BAR',
        title: {
          title: 'Guidance Documents by Published Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['first_published_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'publishedDate'
      }
    ],
    layer2: [
      {
        id: 'status_pie_chart',
        graphType: 'PIE',
        title: {
          title: 'Status',
          show: true
        },
        keys: ['count'],
        index: 'id',
        query: {
          'x-axis': ['status_pie_chart'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'status_pie_chart'
      },
      {
        id: 'keyword_pie_chart',
        graphType: 'PIE',
        title: {
          title: 'Keywords',
          show: true
        },
        query: {
          'x-axis': ['keywords'],
          agg_function: 'count',
          limit: 6
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'keyword_pie_chart'
      }
    ]
  },
  'canada-guidance': {
    layer1: [
      {
        id: 'guidance_documents_by_year',
        graphType: 'BAR',
        title: {
          title: 'Guidance Documents by Issue Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['issued_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'issueDate'
      }
    ],
    layer2: [
      {
        id: 'keyword_pie_chart',
        graphType: 'PIE',
        title: {
          title: 'Keywords',
          show: true
        },
        query: {
          'x-axis': ['keywords'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 },
          startAngle: -270
        },
        data: {},
        apikey: 'keyword_pie_chart'
      },
      {
        id: 'guidance-by-adopted-date',
        graphType: 'BAR',
        title: {
          title: 'Canada Guideline Documents by Adopted Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['date_adopted'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 20, right: 45, bottom: 70, left: 30 }
        },
        data: {},
        apikey: 'guidance-by-adopted-date'
      }
    ]
  },
  hta: {
    layer1: [
      {
        id: 'hta_documents_by_year',
        graphType: 'BAR',
        title: {
          title: 'HTA Documents by Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'issueDateBar'
      }
    ],
    layer2: [
      {
        id: 'nice_doc_type',
        graphType: 'PIE',
        title: {
          title: 'Document Type',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['nice_doc_type'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 },
          labelCharacterLimit: 10,
          startAngle: -20
        },
        data: {},
        apikey: 'nice_doc_type'
      },
      {
        id: 'nice_advice_type',
        graphType: 'PIE',
        title: {
          title: 'Advice Type',
          show: true
        },
        query: {
          'x-axis': ['nice_advice_type'],
          agg_function: 'count',
          limit: 6
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 },
          labelCharacterLimit: 10,
          startAngle: -20
        },
        data: {},
        apikey: 'nice_advice_type'
      },
      {
        id: 'std_status',
        graphType: 'PIE',
        title: {
          title: 'Status',
          show: true
        },
        query: {
          'x-axis': ['std_status'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'std_status'
      }
    ]
  },
  pmr: {
    layer1: [
      {
        id: 'category_graph',
        graphType: 'BAR',
        title: {
          title: 'Number of PMR/PMC And Number of Days From Issue to Fulfillment or Release',
          show: true
        },
        keys: ['days', 'number'],
        index: 'x',
        query: { 'x-axis': ['category_graph'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 45
          },
          colors: BAR_CHART_COLOR,
          groupMode: 'grouped',
          padding: 0.6,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 70, left: 35 },
          defs: [
            {
              id: 'dots',
              type: 'linearGradient',
              colors: [{ color: '#52BCAD' }],
              spacing: 10
            },
            {
              id: 'lines',
              type: 'linearGradient',
              colors: [{ color: '#0A806D' }],
              spacing: 10
            }
          ],
          fill: [
            {
              match: {
                id: 'days'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'number'
              },
              id: 'lines'
            }
          ],
          tickRotationValue: 45
        },
        data: {},
        disableReport: true,
        apikey: 'categoryGraph'
      }
    ],
    layer2: [
      {
        id: 'pmrs_issued_by_year',
        graphType: 'BAR',
        title: {
          title: 'Number of PMR/PMC Issued by Year (and Fulfilled or Released)',
          show: true
        },
        keys: ['y'],
        index: 'x',
        query: { 'x-axis': ['issued_year'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 20, right: 45, bottom: 70, left: 30 }
        },
        data: {},
        apikey: 'issued_year'
      },
      {
        id: 'fulfilled_year',
        graphType: 'BAR',
        title: {
          title: 'PMRs Fulfilled by Year',
          show: true
        },
        keys: ['y'],
        index: 'x',
        query: { 'x-axis': ['fulfilled_year'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 20, right: 45, bottom: 70, left: 30 }
        },
        data: {},
        apikey: 'fulfilled_year'
      }
    ]
  },
  'pmr-surveillance': {
    layer1: [
      {
        id: 'pmrs_by_category',
        graphType: 'BAR',
        title: {
          title: 'Number of PMRs/PMCs by Categories',
          show: true
        },
        keys: ['number'],
        index: 'x',
        query: { 'x-axis': ['category'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 45
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 30, bottom: 70, left: 35 },
          tickRotationValue: 45
        },
        data: {},
        apikey: 'category'
      }
    ],
    layer2: [
      {
        id: 'status',
        graphType: 'PIE',
        title: {
          title: 'PMR / PMC Status',
          show: true
        },
        keys: ['count'],
        index: 'id',
        query: {
          'x-axis': ['status'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 25, left: 30 },
          startAngle: 0
        },
        data: {},
        apikey: 'status'
      },
      {
        id: 'subpart_desc',
        graphType: 'PIE',
        title: {
          title: 'PMRs/PMCs by Subpart Description',
          show: true
        },
        query: {
          'x-axis': ['subpart_desc'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'subpart_desc'
      }
    ]
  },
  'ema-pips': {
    layer1: [
      {
        id: 'drugs_by_determination_year',
        graphType: 'BAR',
        title: {
          title: 'Drugs by Determination Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['decision_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'year_graph'
      }
    ],
    layer2: [
      {
        id: 'route',
        graphType: 'PIE',
        title: {
          title: 'Routes',
          show: true
        },
        query: {
          'x-axis': ['routes'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'routes'
      },
      {
        id: 'formulations',
        graphType: 'PIE',
        title: {
          title: 'Formulations',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['formulations'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'formulations'
      }
    ]
  },
  'fda-written-requests': {
    layer1: [
      {
        id: 'drugs_by_determination_year',
        graphType: 'BAR',
        title: {
          title: 'Drugs by Determination Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['determination_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'year_graph'
      }
    ],
    layer2: [
      {
        id: 'route',
        graphType: 'PIE',
        title: {
          title: 'Routes',
          show: true
        },
        query: {
          'x-axis': ['route'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'route'
      },
      {
        id: 'formulations',
        graphType: 'PIE',
        title: {
          title: 'Formulations',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['formulation'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'formulation'
      }
    ]
  },
  'dd-tools': {
    layer1: [
      {
        id: 'ddt_projects_by_year',
        graphType: 'BAR',
        title: {
          title: 'DDT Projects by Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: { 'x-axis': ['latest_stage_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'latest_stage_date'
      }
    ],
    layer2: [
      {
        id: 'program_type_desc',
        graphType: 'PIE',
        title: {
          title: 'Project Type',
          show: true
        },
        query: {
          'x-axis': ['program_type_desc'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: -10, bottom: 20, left: 30 },
          labelCharacterLimit: 28,
          startAngle: -20
        },
        data: {},
        apikey: 'program_type_desc'
      },
      {
        id: 'submission_status',
        graphType: 'PIE',
        title: {
          title: 'Submission Status',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['submission_status'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 25, left: 30 }
        },
        data: {},
        apikey: 'submission_status'
      }
    ]
  },
  'fda-letters': {
    layer1: [
      {
        id: 'letters_by_year',
        graphType: 'BAR',
        title: {
          title: 'Letters Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: { 'x-axis': ['issue_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'issueDateBar'
      }
    ],
    layer2: [
      {
        id: 'letter_document_type',
        graphType: 'PIE',
        title: {
          title: 'Document Type',
          show: true
        },
        query: {
          'x-axis': ['all_documents_type'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'all_documents_type'
      },
      {
        id: 'issuing_office',
        graphType: 'PIE',
        title: {
          title: 'FDA Issuing Office',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['issuing_office'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 },
          labelCharacterLimit: 20,
          startAngle: -20
        },
        data: {},
        apikey: 'issuing_office'
      }
    ]
  },
  euctr: {
    layer1: [
      {
        id: 'age_category_graph',
        graphType: 'BAR',
        title: {
          title: 'Trials by Age Categories',
          show: true
        },
        keys: ['number'],
        index: 'x',
        query: { 'x-axis': ['age_category'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 45
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 25, bottom: 70, left: 35 },
          tickRotationValue: 45
        },
        data: {},
        apikey: 'age_category_graph'
      },
      {
        id: 'year_trials_graphs',
        graphType: 'BAR',
        title: {
          title: 'Trials By Year',
          show: true
        },
        keys: ['number'],
        index: 'x',
        query: { 'x-axis': ['document_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 35 },
          labelCharacterLimit: 28
        },
        data: {},
        apikey: 'document_date'
      }
    ],
    layer2: [
      {
        id: 'type_of_medicine_graph',
        graphType: 'PIE',
        title: {
          title: 'Type of Medicine',
          show: true
        },
        keys: ['count'],
        index: 'id',
        query: {
          'x-axis': ['type_of_medicine'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'type_of_medicine'
      },
      {
        id: 'scope_of_study_graph',
        graphType: 'PIE',
        title: {
          title: 'Scope of Study',
          show: true
        },
        query: {
          'x-axis': ['scope_of_study'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'scope_of_study_graph'
      }
    ]
  },
  ema_orphan: {
    layer1: [
      {
        id: 'ema_orphan_designations_by_year',
        graphType: 'BAR',
        title: {
          title: 'Opinions on Orphan Medicinal Product Designation by Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: { 'x-axis': ['decision_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'decision_date'
      }
    ],
    layer2: [
      {
        id: 'decision_outcome',
        graphType: 'PIE',
        title: {
          title: 'Decision Outcome',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['decision_outcome'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: 'gray.main',
          radialLabelsTextColor: 'gray.main',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'document_type'
      },
      {
        id: 'designation_status',
        graphType: 'PIE',
        title: {
          title: 'Decision Status',
          show: true
        },
        query: {
          'x-axis': ['designation_status'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 },
          labelCharacterLimit: 20,
          startAngle: -90
        },
        data: {},
        apikey: 'designation_status'
      },
      {
        id: 'age_range',
        graphType: 'PIE',
        title: {
          title: 'Age Ranges',
          show: true
        },
        query: {
          'x-axis': ['age_range'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: -25, bottom: 20, left: 30 },
          labelCharacterLimit: 15,
          startAngle: 0
        },
        data: {},
        apikey: 'age_range'
      }
    ]
  },
  'canada-consultation': {
    layer1: [
      {
        id: 'consultation_documents_by_year',
        graphType: 'BAR',
        title: {
          title: 'Consultations by Start Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: { 'x-axis': ['start_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'start_date'
      }
    ],
    layer2: [
      {
        id: 'status_pie_chart',
        graphType: 'PIE',
        title: {
          title: 'Organization',
          show: true
        },
        keys: ['count'],
        index: 'id',
        query: {
          'x-axis': ['status'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'status'
      },
      {
        id: 'subject_pie_chart',
        graphType: 'PIE',
        title: {
          title: 'Subjects',
          show: true
        },
        query: {
          'x-axis': ['subjects'],
          agg_function: 'count',
          limit: 5
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'subjects'
      }
    ]
  },
  adcomm: {
    layer1: [
      {
        id: 'meeting_by_year',
        graphType: 'BAR',
        title: {
          title: 'Total Number of Meeting by Year',
          show: true
        },
        keys: ['Year'],
        index: 'name',
        query: { 'x-axis': ['start_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'year_graph'
      }
    ],
    layer2: [
      {
        id: 'appl_type_pie_chart',
        graphType: 'PIE',
        title: {
          title: 'NDAs vs BLAs vs Other',
          show: true
        },
        keys: ['count'],
        index: 'id',
        query: {
          'x-axis': ['appl_type'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'appl_type'
      },
      {
        id: 'appl_type_post_acm',
        graphType: 'PIE',
        title: {
          title: 'Post Meeting Approvals',
          show: true
        },
        query: {
          'x-axis': ['approval_decision'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'approval_decision'
      }
    ]
  },
  chmp: {
    layer1: [
      {
        id: 'meeting_insights',
        graphType: 'PIE',
        title: {
          title: 'Meeting Insights',
          show: true
        },
        query: {
          'x-axis': ['highlights'],
          agg_function: 'count'
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'highlights'
      }
    ],
    layer2: [
      {
        id: 'meetings_by_year',
        graphType: 'BAR',
        title: {
          title: 'Total Number of Unique Products by Years',
          show: true
        },
        keys: ['year'],
        index: 'key',
        query: { 'x-axis': ['year'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'year'
      }
    ]
  },
  'usfda-business-intelligence': {
    layer1: [
      {
        id: 'us_result_approval_by_year',
        graphType: 'BAR',
        title: {
          title: 'Approval(s) by Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['approval_year'],
          agg_function: 'count',
          sort_on_axis: 'x'
        },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'approval_year'
      },
      {
        id: 'us_result_approval_time_by_year',
        graphType: 'BAR',
        title: {
          title: 'Median Approval Time, Days from Filing',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['approval_year'],
          'y-axis': ['approval_time'],
          agg_function: 'median',
          sort_on_axis: 'x'
        },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'approval_time'
      }
    ],
    layer2: [
      {
        id: 'accelerated_approval_percentage',
        graphType: 'CIRCULAR_PROGRESS',
        title: {
          title: 'Accelerated Approvals',
          show: true
        },
        keys: 'Yes',
        query: {
          'x-axis': ['accelerated_approval'],
          agg_function: 'count'
        },
        graphStyle: {
          thickness: 3,
          color: '#0D917D'
        },
        data: {},
        apikey: 'accelerated_approval'
      },
      {
        id: 'breakthrough_designation_percentage',
        graphType: 'CIRCULAR_PROGRESS',
        title: {
          title: 'Breakthrough Designation',
          show: true
        },
        keys: 'Yes',
        query: {
          'x-axis': ['breakthrough_status'],
          agg_function: 'count'
        },
        graphStyle: {
          thickness: 3,
          color: '#0D917D'
        },
        data: {},
        apikey: 'breakthrough_status'
      },
      {
        id: 'fast_track_percentage',
        graphType: 'CIRCULAR_PROGRESS',
        title: {
          title: 'Fast Track',
          show: true
        },
        keys: 'Yes',
        query: {
          'x-axis': ['fast_track'],
          agg_function: 'count'
        },
        graphStyle: {
          thickness: 3,
          color: '#0D917D'
        },
        data: {},
        apikey: 'fast_track'
      },
      {
        id: 'orphan_designation_percentage',
        graphType: 'CIRCULAR_PROGRESS',
        title: {
          title: 'Orphan Designation',
          show: true
        },
        keys: 'Yes',
        query: {
          'x-axis': ['orphan_status'],
          agg_function: 'count'
        },
        graphStyle: {
          thickness: 3,
          color: '#0D917D'
        },
        data: {},
        apikey: 'orphan_status'
      }
    ]
  },
  'clinical-trials-us': {
    layer1: [
      {
        id: 'number_of_trials_by_completion_year',
        graphType: 'BAR',
        title: {
          title: 'Number of Trials by Year of Completion',
          show: true
        },
        keys: ['data'],
        index: 'name',
        query: { 'x-axis': ['study_completion_year'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.7,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'year_graph'
      },
      {
        id: 'number_of_trials_by_phase',
        graphType: 'BAR',
        title: {
          title: 'Number of Trials by Phase',
          show: true
        },
        keys: ['data'],
        index: 'name',
        query: { 'x-axis': ['phase'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.5,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 20, bottom: 40, left: 130 },
          layout: 'horizontal'
        },
        data: {},
        apikey: 'phase_graph'
      }
    ],
    layer2: [
      {
        id: 'number_of_patients_by_trials',
        graphType: 'PLOT_GRAPH',
        title: {
          title: 'NNumber of Patients by Trial',
          show: true
        },
        keys: ['data'],
        index: 'name',
        query: {},
        graphStyle: {
          layout: {
            barmode: 'stack',
            autosize: true,
            margin: { t: 32, r: 140, l: 90 },
            showlegend: false,
            xaxis: {
              tickangle: 90,
              showticklabels: true,
              color: '#9E9E9E',
              tickfont: {
                size: 12,
                color: '#757575',
                family: 'Roboto, Arial, sans-serif',
                weight: 400
              }
            },
            orientation: 90,
            dragmode: 'pan',
            bargap: 0.1,
            bargroupgap: 0.5
          },
          marker: {
            color: BAR_CHART_COLOR[0]
          },
          config: {
            toImageButtonOptions: {
              filename: `number_of_patients_by_trial`
            },
            scrollZoom: true
          }
        },
        data: {},
        disableReport: true,
        isExpandedButton: true,
        apikey: 'enrollment_by_trial',
        toolTipText: 'View Number of Patients by Region of Enrollment'
      },
      {
        id: 'number_of_trials_by_recruitment_status',
        graphType: 'BAR',
        title: {
          title: 'Number of Trials by Recruitment Status',
          show: true
        },
        keys: ['data'],
        index: 'name',
        query: { 'x-axis': ['overall_status'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.5,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 20, bottom: 40, left: 200 },
          layout: 'horizontal'
        },
        data: {},
        apikey: 'status'
      }
    ]
  },
  'clinical-trials-eu': {
    layer1: [
      {
        id: 'study_completion_year',
        graphType: 'BAR',
        title: {
          title: 'Number of Trials by Year of Completion',
          show: true
        },
        keys: ['data'],
        index: 'name',
        query: { 'x-axis': ['study_completion_year'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.7,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 25, left: 30 }
        },
        data: {},
        apikey: 'year_graph'
      }
    ],
    layer2: [
      {
        id: 'phase',
        graphType: 'BAR',
        title: {
          title: 'Number of Trials by Phase',
          show: true
        },
        keys: ['data'],
        index: 'name',
        query: { 'x-axis': ['phase'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.5,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 10, right: 20, bottom: 25, left: 120 },
          layout: 'horizontal'
        },
        data: {},
        apikey: 'phase_graph'
      }
    ]
  },
  'speciality-desgination': {
    layer1: [
      {
        id: 'desgination_by_year',
        graphType: 'BAR',
        title: {
          title: 'Designation(s) by Year',
          show: true
        },
        keys: ['value'],
        index: 'id',
        query: {},
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 40, left: 30 }
        },
        data: {},
        apikey: 'desgination_by_year',
        disableReport: true
      }
    ],
    layer2: [
      {
        id: 'designations',
        graphType: 'PIE',
        title: {
          title: 'Designations',
          show: true
        },
        query: {},
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: '#707070',
          radialLabelsTextColor: '#707070',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'designations',
        disableReport: true
      },
      {
        id: 'designations_by_indications',
        graphType: 'DATAGRID',
        title: {
          title: 'Designations by Indications'
        },
        query: {},
        config: {
          columnsMapping: [
            {
              field: 'indication',
              headerName: 'Indications',
              headerClassName: 'table-header',
              width: 400,
              cellClassName: 'table-row'
            },
            {
              field: 'value',
              headerName: 'Count',
              headerClassName: 'table-header',
              width: 450,
              cellClassName: 'table-row'
            }
          ],
          csvFileName: `indications.csv`,
          rowId: 'id'
        },
        data: [],
        apikey: 'designations',
        disableReport: true
      }
    ]
  },
  'mhra-guidance': {
    layer1: [
      {
        id: 'guidance_documents_by_year',
        graphType: 'BAR',
        title: {
          title: 'Guidance Documents by Year',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
        graphStyle: {
          axisLeft: {
            tickRotation: 0
          },
          axisBottom: {
            tickRotation: 80
          },
          colors: BAR_CHART_COLOR,
          padding: 0.8,
          labelTextColor: '#ffffff',
          axisText: '#9E9E9E',
          margin: { top: 30, right: 0, bottom: 70, left: 30 }
        },
        data: {},
        apikey: 'publishedDate'
      }
    ],
    layer2: [
      {
        id: 'topics',
        graphType: 'PIE',
        title: {
          title: 'Topics',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['all_topics'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: 'gray.main',
          radialLabelsTextColor: 'gray.main',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'all_topics'
      },
      {
        id: 'subtopics',
        graphType: 'PIE',
        title: {
          title: 'Subtopics',
          show: true
        },
        keys: ['count'],
        index: 'year',
        query: {
          'x-axis': ['all_subtopics'],
          agg_function: 'count',
          limit: 3
        },
        graphStyle: {
          colors: PIECHARTCOLOR,
          pieLabel: true,
          pieLegend: false,
          innerRadius: 0.4,
          enableLabels: 'both',
          fillText: 'gray.main',
          radialLabelsTextColor: 'gray.main',
          margin: { top: 20, right: 45, bottom: 20, left: 30 }
        },
        data: {},
        apikey: 'all_subtopics'
      }
    ]
  }
};

export const EUCT_PLOT_GRAPH = {
  id: 'number_of_patients_by_trials',
  graphType: 'PLOT_GRAPH',
  title: {
    title: 'Number of Patients by Trial',
    show: true
  },
  query: {},
  graphStyle: {
    layout: {
      barmode: 'stack',
      autosize: true,
      margin: { t: 32, r: 140, l: 120 },
      showlegend: false,
      xaxis: {
        tickangle: 90,
        showticklabels: true,
        color: '#9E9E9E',
        tickfont: {
          size: 12,
          color: '#757575',
          family: 'Roboto, Arial, sans-serif',
          weight: 400
        }
      },
      orientation: 90,
      dragmode: 'pan',
      bargap: 0.1,
      bargroupgap: 0.5
    },
    marker: {
      color: BAR_CHART_COLOR[0]
    },
    config: {
      toImageButtonOptions: {
        filename: `number_of_patients_by_trial`
      },
      scrollZoom: true
    }
  },
  data: {},
  disableReport: true,
  apikey: 'enrollment_by_trial'
};

export const SKELETON_HEIGHT = [
  { id: 1, height: '90%', width: '.2%' },
  { id: 2, height: '60%', width: '3%' },
  { id: 3, height: '80%', width: '3%' },
  { id: 4, height: '30%', width: '3%' },
  { id: 5, height: '50%', width: '3%' },
  { id: 6, height: '50%', width: '3%' },
  { id: 7, height: '60%', width: '3%' },
  { id: 8, height: '80%', width: '3%' },
  { id: 9, height: '30%', width: '3%' },
  { id: 10, height: '50%', width: '3%' },
  { id: 11, height: '50%', width: '3%' }
];

export default VISUALIZE_CHART_MAPPING;
