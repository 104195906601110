import { MHRA_GUIDANCE_PIE_CHART } from '../const';
import getTopValuesForPieChart from '../../../utils/getTopValuesForDonutChart';

export const constructPieChartData = (guidanceData: any) => {
  const graphs: any = [];
  MHRA_GUIDANCE_PIE_CHART.forEach((item: any) => {
    graphs.push({
      label: item.label,
      children: getTopValuesForPieChart(
        guidanceData[item.graphItem],
        item.apiKey,
        item.count,
        false
      ),
      graphItem: item.graphItem
    });
  });
  return graphs;
};

export default constructPieChartData;
