/* eslint-disable no-underscore-dangle */
import React, { useCallback, useContext, useMemo, useEffect, useState } from 'react';

// MUI
import Stack from '@mui/material/Stack';

// Custom Components
import {
  Box,
  Button,
  Checkbox,
  debounce,
  Divider,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import VivproDatagrid from '../../../components/Datagrid';

// store
import store from '../../../store/MHRAGuidance';

import { getFileName } from '../../../helpers/getFileName';

import PDFCellComponent from '../../Guidance/components/PDFCellRenderer';

// constants
import { MHRA_GUIDANCE_COLUMN, CSV_FILENAME } from '../const';
import getTableColumnWithCellRenderers from '../../../utils/getTableColumnWithCellRenders';
import styles from '../../Guidance/styles/Details.styles';
import actions from '../../../store/MHRAGuidance/actions';
import MagnifyingGlassIcon from '../../../assets/svgs/Icons/MagnifyingGlassIcon';

const MHRAGuidanceDetails = ({
  isFilterUpdated,
  setIsFilterUpdated,
  isComparison = false,
  openComparisonPopup = null,
  getMHRAGuidanceData = () => {}
}: any) => {
  const datagridMessage = 'Loading...';
  // eslint-disable-next-line no-unused-vars
  const [pdfUrl, setPdfUrl] = useState('');
  const { guidanceState, guidanceDispatch } = useContext(store);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  useEffect(() => {
    if (!isComparison) {
      getMHRAGuidanceData([]);
      guidanceDispatch({
        type: actions.CLEAR_DOCUMENT_TO_COMPARE
      });
    }
  }, [isComparison]);

  const handlePDFOpenClick = useCallback((pdfLink: any) => {
    setPdfUrl(pdfLink);
  }, []);

  const pdfCellRenderer = useCallback(
    ({ value, rowData }: any) => (
      <PDFCellComponent value={value} rowData={rowData} onPDFButtonClick={handlePDFOpenClick} />
    ),
    [handlePDFOpenClick]
  );

  const tableColumns = useMemo(
    () =>
      getTableColumnWithCellRenderers(MHRA_GUIDANCE_COLUMN, [
        { field: 's3_path', cellRenderer: pdfCellRenderer }
      ]),
    [pdfCellRenderer]
  );

  const handleComparisonCheckbox = useCallback(
    async (id: string) => {
      // eslint-disable-next-line no-underscore-dangle
      if (guidanceState.documentsToCompare.find((doc: any) => doc._id === id)) {
        guidanceDispatch({
          type: actions.REMOVE_DOCUMENT_TO_COMPARE,
          // eslint-disable-next-line no-underscore-dangle
          value: id
        });
      } else {
        guidanceDispatch({
          type: actions.SET_DOCUMENT_TO_COMPARE,
          // eslint-disable-next-line no-underscore-dangle
          value: guidanceState.tableData.find((doc: any) => doc._id === id)
        });
      }
    },
    [guidanceState.tableData, guidanceState.documentsToCompare]
  );

  const renderAbleTableColumns = useMemo(() => {
    const renderAbleColumns = [...tableColumns];
    if (isComparison) {
      renderAbleColumns.unshift({
        field: '_id',
        headerName: 'Select',
        width: 100,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
        disableExport: true,
        renderCell: (params: any) => {
          const row = params?.row;
          const value = params?.value;
          return (
            <Tooltip
              title={
                guidanceState.documentsToCompare.length === 2 &&
                !guidanceState.documentsToCompare.find((doc: any) => doc._id === value)
                  ? 'Selection limit reached'
                  : ''
              }>
              <Box key={value}>
                <Checkbox
                  disabled={
                    row?.documents?.length === 0 ||
                    (guidanceState.documentsToCompare.length === 2 &&
                      !guidanceState.documentsToCompare.find((doc: any) => doc._id === value))
                  }
                  checked={!!guidanceState.documentsToCompare.find((doc: any) => doc._id === value)}
                  onClick={() => handleComparisonCheckbox(value)}
                />
              </Box>
            </Tooltip>
          );
        }
      });
    }
    return renderAbleColumns;
  }, [
    guidanceState.documentsToCompare,
    tableColumns,
    guidanceState.compareEnabled,
    guidanceState.tableData,
    guidanceState.dataGridFilters
  ]);

  const updateSearch = useCallback(
    (query: string) => {
      const allFilters = {
        ...guidanceState.availableFilters,
        ...(query?.length > 0 ? { filter_keywords: [query] } : {})
      };

      if (query?.length < 1) {
        delete allFilters.filter_keywords;
      }
      getMHRAGuidanceData(allFilters);
    },
    [guidanceState.availableFilters]
  );

  const debouncedChangeHandler = useMemo(() => debounce(updateSearch, 200), [updateSearch]);

  const handleInputTextChange = (event: any) => {
    setSearchQuery(event.target.value);
    debouncedChangeHandler(event.target.value);
  };

  return (
    <Stack sx={guidanceState.compareEnabled ? styles.rootWithComparisonEnabled : styles.root}>
      {isComparison && (
        <>
          <Box mt={2}>
            <Stack direction='row' sx={styles.drawerNav}>
              <TextField
                sx={styles.textField}
                placeholder='Find Guidelines'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <MagnifyingGlassIcon sx={styles.searchIcon} />
                    </InputAdornment>
                  )
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 75
                }}
                onChange={handleInputTextChange}
                value={searchQuery}
              />
              <Stack direction='row' spacing={2} alignItems='center'>
                <Box>
                  <Typography
                    sx={{
                      ...styles.selectedText,
                      ...(guidanceState.documentsToCompare.length > 0 && { color: 'gray.800' })
                    }}>
                    {guidanceState.documentsToCompare.length} selected
                  </Typography>
                </Box>
                <Divider orientation='vertical' sx={styles.verticalDivider} />
                <Box>
                  <Typography
                    onClick={() => {
                      guidanceDispatch({
                        type: actions.CLEAR_DOCUMENT_TO_COMPARE
                      });
                    }}
                    sx={{
                      ...styles.resetText,
                      ...(guidanceState.documentsToCompare.length > 0 && {
                        color: 'primary.600',
                        cursor: 'pointer'
                      })
                    }}>
                    Reset
                  </Typography>
                </Box>
                <Divider orientation='vertical' sx={styles.verticalDivider} />
                <Button
                  sx={styles.compareButton}
                  onClick={openComparisonPopup}
                  disabled={guidanceState.documentsToCompare.length !== 2}>
                  Compare
                </Button>
              </Stack>
            </Stack>
            <Divider sx={styles.hrDivider} />
          </Box>
          <Stack direction='row' sx={styles.statsContainer}>
            <Box sx={styles.statsWrapper}>
              <Typography sx={styles.statsText}>
                Showing <strong>{guidanceState?.totalRecord ?? 0}</strong> of{' '}
                <strong>{guidanceState?.guidanceStats?.totalGuidance ?? 0} Guidelines</strong>
              </Typography>
            </Box>
          </Stack>
        </>
      )}
      <VivproDatagrid
        rows={guidanceState?.tableData ?? []}
        columnsMapping={renderAbleTableColumns}
        rowId='_id'
        csvFileName={getFileName(CSV_FILENAME)}
        noRowMessage={datagridMessage}
        loading={guidanceState.loading}
        rowCount={guidanceState?.totalRecord ?? 0}
        isServerSideExport={false}
        isFilterModeServer={false}
        allowFilterPanelWithLogicOperators
      />
    </Stack>
  );
};

export default React.memo(MHRAGuidanceDetails);
