interface Topic {
  label: string;
  subtopics: string[];
}

const getTopicsString = (topics: Topic[]) => {
  return topics
    .map(topic => {
      return `${topic.label}\n(${topic.subtopics.join('; ')})`;
    })
    .join('\n');
};

export default getTopicsString;
