const styles = {
  wrapper: {
    alignItems: 'center',
    lineHeight: '24px',
    width: 1,
    height: 1,
    position: 'relative',
    display: 'flex'
  },
  cellDiv: {
    height: 1,
    display: 'block',
    position: 'absolute',
    top: 0
  },
  clamppedText: {
    whiteSpace: 'normal !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    width: '100%',
    lineHeight: '21px'
  },
  popperPaper: {
    maxHeight: 300,
    overflowY: 'auto',
    borderRadius: '8px',
    p: 1,
    width: 400
  },
  tagText: {
    height: 16,
    px: 0.5,
    borderRadius: 1,
    bgcolor: 'secondary.50',
    color: 'secondary.800',
    fontSize: 12,
    fontWeight: 500,
    display: 'inline-block',
    mr: 1
  }
};

export default styles;
