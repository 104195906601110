import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import { getAllGuidanceDocuments, getGuidanceDocs } from '../../../api/pages/MHRAGuidance';

// store
import MHRAGuidanceStore from '../../../store/MHRAGuidance';
import actions from '../../../store/MHRAGuidance/actions';
import globalActions from '../../../store/actions';
import globalStore from '../../../store/index';

// utils
import { constructPieChartData } from '../utils/getDonutChartStructure';
import { mergeOldFilterValues } from '../../../utils/filters';
import getFilterOptions from '../utils/common';
import { EXPORT_TYPE, MHRA_GUIDANCE_COLUMN_NAME_MAPPING } from '../const';
import { getFileName } from '../../../helpers/getFileName';
import transformMHRAGuidanceData from '../utils/transformMHRAGuidanceData';

const useMHRAGuidanceData = () => {
  const { guidanceState, guidanceDispatch } = useContext(MHRAGuidanceStore);
  const { state, dispatch }: any = useContext(globalStore);

  const getMHRAGuidanceTableData = async (
    payload: any,
    saveFilters: boolean = true,
    cancelToken = null
  ) => {
    const res = await getGuidanceDocs(payload, cancelToken);
    if (res?.data?.body?.data) {
      guidanceDispatch({
        type: actions.SET_GUIDANCE_TABLE,
        value: res?.data?.body?.data ?? []
      });
      guidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.stats.total_guidances ?? 0
      });

      const graphs = constructPieChartData(res?.data?.body?.stats?.donut_graph_data);
      guidanceDispatch({
        type: actions.SET_DONUT_CHARTS,
        value: graphs
      });

      guidanceDispatch({
        type: actions.SET_PUBLISHED_DATE,
        value: res?.data?.body?.stats?.bar_graph_data
      });

      const stats = {
        totalLastThirtyDays: res?.data?.body?.stats?.last_30_days_count,
        totalGuidance: res?.data?.body?.stats?.total_guidances,
        pdfCount: res?.data?.body?.stats?.total_documents_count,
        pageCount: res?.data?.body?.stats?.total_page_count
      };

      guidanceDispatch({
        type: actions.SET_GUIDANCE_STATS,
        value: stats
      });

      let mergedFilters: any = [];
      const newFilters = getFilterOptions(res?.data?.body?.filters);
      if (payload.filters.length) {
        mergedFilters = mergeOldFilterValues(newFilters, payload.filters);
      } else {
        mergedFilters = newFilters;
      }

      guidanceDispatch({
        type: actions.SET_FILTERS,
        value: mergedFilters
      });
      guidanceDispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        // eslint-disable-next-line no-underscore-dangle
        value: res?.data?.body?.data?.map((d: any) => d._id) || []
      });
      if (saveFilters) {
        guidanceDispatch({
          type: actions.SET_AVAILABLE_FILTERS,
          value: payload.filters
        });
      }
    }
  };

  const getMHRAGuidanceData = useCallback(async (appliedFilters: any) => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    guidanceDispatch({
      type: actions.SET_IS_FILTERS_APPLIED,
      value: Object.keys(appliedFilters).length !== 0
    });

    const payload: any = {};
    payload.filters = { ...appliedFilters };
    guidanceDispatch({
      type: actions.SET_AVAILABLE_FILTERS,
      value: appliedFilters
    });
    try {
      // get table data
      await getMHRAGuidanceTableData(payload);

      guidanceDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: {}
      });

      guidanceDispatch({
        type: actions.SET_LOADING,
        value: false
      });
    } catch (err) {
      const errors: any = [];
      errors.push('No data was found');
      guidanceDispatch({
        type: actions.SET_GUIDANCE_ERRORS,
        action: errors
      });
      guidanceDispatch({
        type: actions.SET_LOADING,
        value: false
      });
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const searchMHRAGuidanceData = useCallback(async (appliedFilters: any, cancelToken: any) => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    const payload: any = {};
    payload.filters = { ...appliedFilters };

    try {
      // get table data
      await getMHRAGuidanceTableData(payload, false, cancelToken);

      guidanceDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: {}
      });

      guidanceDispatch({
        type: actions.SET_LOADING,
        value: false
      });
    } catch (err) {
      const errors: any = [];
      errors.push('No data was found');
      guidanceDispatch({
        type: actions.SET_GUIDANCE_ERRORS,
        action: errors
      });
      guidanceDispatch({
        type: actions.SET_LOADING,
        value: false
      });
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const openGuidanceComparison = useCallback((value: boolean) => {
    guidanceDispatch({
      type: actions.SET_COMPARE_ENABLED,
      value
    });
  }, []);
  const removeFromComparison = useCallback((id: string) => {
    guidanceDispatch({
      type: actions.REMOVE_DOCUMENT_TO_COMPARE,
      value: id
    });
  }, []);

  const openComparisonPopup = useCallback((value: boolean) => {
    guidanceDispatch({
      type: actions.SET_COMPARE_POPUP_OPEN,
      value
    });
  }, []);

  const clearComparison = useCallback(() => {
    guidanceDispatch({
      type: actions.CLEAR_DOCUMENT_TO_COMPARE
    });
    openComparisonPopup(false);
  }, []);

  const clearSearch = useCallback(() => {
    guidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: ''
      }
    });
  }, []);

  const handleCSVExport = async () => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllGuidanceDocuments({
      filters: guidanceState.availableFilters
    });

    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? MHRA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(MHRA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformMHRAGuidanceData(
      res?.data?.Success?.mhra_guidance_documents,
      selectedColumns
    );
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'mhra_guidances',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllGuidanceDocuments({
      filters: guidanceState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? MHRA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(MHRA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformMHRAGuidanceData(
      res?.data?.Success?.mhra_guidance_documents,
      selectedColumns
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'mhra_guidances',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    guidancefilters: guidanceState.filters,
    isFilterApplied: guidanceState.isFiltersApplied,
    mhraGuidanceTableData: guidanceState.tableData,
    isLoading: guidanceState.loading,
    compareEnabled: guidanceState.compareEnabled,
    documentsToCompare: guidanceState.documentsToCompare,
    comparePopupOpen: guidanceState.comparePopupOpen,
    getMHRAGuidanceData,
    openComparisonPopup,
    removeFromComparison,
    openGuidanceComparison,
    clearComparison,
    clearSearch,
    sortFields: guidanceState.sortFields,
    dataGridFilter: guidanceState.dataGridFilters,
    searchMHRAGuidanceData
  };
};

export default useMHRAGuidanceData;
