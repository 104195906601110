import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface GuidanceContextInterface {
  publishedDateBar: any[];
  guidanceStats: any;
  tableData: any[];
  productDonut: any[];
  fdaOrgDonut: any[];
  docTypeDonut: any[];
  donutCharts: any[];

  guidanceErrors: any[];
  filters: any[];
  availableFilters: any;
  loading: boolean;
  isFiltersApplied: boolean;

  documentsToCompare: any[];
  compareEnabled: boolean;
  comparePopupOpen: boolean;

  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const initialState = {
  guidanceStats: {},
  tableData: [],

  productDonut: [],
  fdaOrgDonut: [],
  docTypeDonut: [],
  publishedDateBar: [],
  donutCharts: [],

  guidanceErrors: [],
  filters: [],
  availableFilters: {},
  search: {},
  loading: true,
  isFiltersApplied: false,

  documentsToCompare: [],
  compareEnabled: false,
  comparePopupOpen: false,

  totalRecord: 0,
  sortFields: [],
  askRiaFilters: [],
  dataGridFilters: {}
};

const GuidanceStore = createContext<{
  guidanceState: GuidanceContextInterface;
  guidanceDispatch: React.Dispatch<any>;
}>({ guidanceState: initialState, guidanceDispatch: () => null });
const { Provider } = GuidanceStore;

export const GuidanceStateProvider = ({ children }: Props) => {
  const [guidanceState, guidanceDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ guidanceState, guidanceDispatch }}>{children}</Provider>;
};

export default GuidanceStore;
