import { createSvgIcon } from '@mui/material';

const ArrowDropRightIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='5' height='10' viewBox='0 0 5 10' fill='none'>
    <path
      d='M0.470703 9.86191V0.528576L4.66604 4.72391C4.79102 4.84893 4.86123 5.01847 4.86123 5.19524C4.86123 5.37202 4.79102 5.54156 4.66604 5.66658L0.470703 9.86191Z'
      fill='#757575'
    />
  </svg>,
  'AngleRight'
);

export default ArrowDropRightIcon;
