import React, { useCallback, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { groupBy } from 'lodash';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import { FolderIcon } from '../../../assets/svgs/Icons';
import { getGuidanceDocumentsSignedURL } from '../../../api/pages/MHRAGuidance';

import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import getDateString from '../../../utils/getDateString';
import DocumentListing from '../../../components/Dialogs/DocumentListing';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const getPDFUrlFromS3Path = useCallback(async (pdfLink: any) => {
    const res = await getGuidanceDocumentsSignedURL({
      s3_bucket: 'vivpro-mhra-guidance',
      s3_path: pdfLink
      // eslint-disable-next-line no-console
    }).catch(err => console.error(err));

    return res?.data?.body?.s3_url;
  }, []);

  const { documents, documentCount }: { documents: DocumentsGroup[]; documentCount: number } =
    useMemo(() => {
      const count = data.documents.length;
      const groupedDocuments = groupBy(data.documents, 'document_type');
      const groups = Object.keys(groupedDocuments).map(key => {
        const group: DocumentsGroup = {
          id: key,
          categoryTitle: key,
          documents: [] as DocumentItem[]
        };
        const docs = groupedDocuments[key].map((doc: any) => {
          const { document_title: docTitle, s3_path: path, document_date: documentDate } = doc;

          return {
            title: docTitle,
            title_link: path,
            getPdfUrl: getPDFUrlFromS3Path,
            chatRIAMetadata: {
              _category: key ?? ''
            },
            metadata: [documentDate ? `Last Updated: ${getDateString(documentDate)}` : ''].filter(
              (title: string) => !!title
            )
          };
        });

        group.documents = docs;

        return group;
      });

      return { documents: groups, documentCount: count };
    }, []);

  const chatRIAMetadata = useMemo(() => {
    const { title, published_date: publishedDate, updated_date: updatedDate } = data;
    return {
      published_date: publishedDate ?? '',
      updated_date: updatedDate ?? '',
      title: title ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    return [
      data?.published_date ? `Published Date: ${getDateString(data?.published_date)}` : ''
    ].filter((title: string) => !!title);
  }, [data]);

  const mappedSourceName = getDocumentSourceName('MHRA Guideline');

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>
      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={data?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source={mappedSourceName as string}
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
          allowComparison={documentCount > 1}
        />
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  return <GridCellDocuments data={params.row} />;
};

export default DocumentHistoryPDF;
