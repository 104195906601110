import { GridColDef } from '@mui/x-data-grid-pro';
import { capitalize } from 'lodash';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import DatagridChipsPopup from '../../components/Datagrid/DatagridChipsPopup';

import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { Filter } from '../../components/Filters';
import DocumentHistoryPDF from './components/DocumentHistoryPDF';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const SEARCH_IGNORE_FIELDS = ['document_history', '_id'];

export interface CandadaGuidancesFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

type CustomGridColDef = GridColDef & {
  capitalCasing?: boolean;
};

export const CANADA_GUIDANCE_COLUMN: CustomGridColDef[] = [
  {
    field: 'document_history',
    headerName: 'Documents',
    width: 100,
    filterable: false,
    capitalCasing: false,
    headerClassName: 'table-header',
    disableExport: true,
    sortable: false,
    renderCell: DocumentHistoryPDF
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: (params: any) => params.value.replace(/ \([\s\S]*?\)/g, '')
  },
  {
    field: 'description',
    headerName: 'Summary',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'issued_date',
    headerName: 'Issue Date',
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'date_adopted',
    headerName: 'Adopted Date',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'last_modified_date',
    headerName: 'Last Updated Date',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'document_version',
    headerName: 'Document Type',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => capitalize(params.value) || '-'
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    width: 300,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridChipsPopup,
    // eslint-disable-next-line consistent-return
    valueGetter: (params: any) => {
      const newValue: Array<string> = [];
      if (params.value) {
        params.value.forEach((value: any) => {
          if (value) {
            newValue.push(capitalize(value.trim()));
          }
        });
        return newValue.join('; ');
      }
    }
  },
  {
    field: 'effective_date',
    headerName: 'Legal Effective Date',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'revised_date',
    headerName: 'Revised Date',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  }
];

export const CANADA_GUIDANCE_FILTERS = [
  {
    id: 1,
    label: 'Keywords',
    value: 'keywords',
    elasticSearchType: 'keywords',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Title',
    value: 'title',
    elasticSearchType: 'title',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Adopted Date',
    value: 'date_adopted',
    elasticSearchType: 'date_adopted',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Last Updated Date',
    value: 'last_modified_date',
    elasticSearchType: 'last_modified_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Legal Effective Date',
    value: 'effective_date',
    elasticSearchType: 'effective_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Issue Date',
    value: 'issued_date',
    elasticSearchType: 'issued_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'canada_guidances',
  postfix: date,
  additional: '',
  extension: ''
};

export const ENTITY_MAPPING: { [key: string]: any } = {
  description: { name: 'Description' },
  issue_date: { name: 'Issue Date ' },
  effective_date: { name: 'Legal Effective Date ' },
  last_modified_date: { name: 'Last Updated Date ' },
  date_adopted: { name: 'Adopted Date ' }
};

export const CANADA_GUIDANCE_PIE_CHART = [
  {
    label: 'Keywords',
    apiKey: 'id',
    graphItem: 'keyword_pie_chart',
    count: 5
  }
];
export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 25
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const CANADA_GUIDANCE_REPORT_QUERIES = [
  {
    id: 'guidance_documents_by_year',
    title: {
      title: 'Canada Guideline Documents by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['issued_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      },
      axisBottom: {
        tickRotation: 80
      }
    }
  },
  {
    id: 'guidance-by-adopted-date',
    title: {
      title: 'Canada Guidance Documents by Adopted Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['date_adopted'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'keyword_pie_chart',
    title: {
      title: 'Keywords',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['keywords'],
      agg_function: 'count',
      limit: 5
    },
    graphStyle: pieChartStyles
  }
];

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const CANADA_GUIDANCE_COLUMN_NAME_MAPPING = {
  title: 'Title',
  description: 'Summary',
  issued_date: 'Issue Date',
  date_adopted: 'Adopted Date',
  last_modified_date: 'Last Updated Date',
  document_version: 'Document Type',
  keywords: 'Keywords',
  effective_date: 'Legal Effective Date',
  revised_date: 'Revised Date'
};

export default CANADA_GUIDANCE_FILTERS;
