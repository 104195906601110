import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { List, ListItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import datagridStyles from '../Datagrid.styles';
import customTitleCase from '../../../utils/titleCase';
import styles from './styles';

interface GridCellExpandProps {
  width: number;
  unformattedValue: any;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, unformattedValue } = props;

  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    if (unformattedValue.length > 0) {
      setShowPopper(true);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    }
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={styles.datagridCellWrapper}>
      <Box ref={cellDiv} sx={{ ...styles.cellDiv, width }} />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...datagridStyles.datagridCellText
        }}
        /* If we want customize the cell through its parent, we can use this class from the parent */
        className='cellValue'>
        {unformattedValue.length > 0 ? (
          <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <Box sx={{ color: 'black.main', mr: 1 }}>
              <CircleIcon sx={{ fontSize: '6px' }} />
            </Box>
            <Box sx={{ overflow: 'hidden' }}>
              <Typography
                sx={{
                  ...datagridStyles.datagridCellText,
                  whiteSpace: 'normal !important',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  width: '100%'
                }}>
                {customTitleCase(unformattedValue[0]?.label)} (
                {unformattedValue[0].subtopics?.join?.('; ')})...
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{
              ...datagridStyles.datagridCellText,
              textAlign: 'center'
            }}>
            -
          </Typography>
        )}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement='bottom'
          style={{ width, marginLeft: -17, zIndex: 1300 }}>
          <Paper
            elevation={1}
            sx={{ ...styles.paper, minHeight: (wrapper?.current?.offsetHeight || 200) - 3 }}>
            <List sx={{ p: 0 }}>
              {unformattedValue.map((item: any) => (
                <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                  <Typography
                    sx={{
                      color: 'gray.800',
                      fontWeight: '700',
                      fontSize: 13
                    }}>
                    {customTitleCase(item.label)}
                  </Typography>
                  {!!item.subtopics?.length && (
                    <List sx={{ pt: 0, pb: 2 }}>
                      {item.subtopics.map((subtopic: string) => (
                        <ListItem
                          key={subtopic}
                          disablePadding
                          sx={{ pl: 1, dispaly: 'flex', alignItems: 'center' }}>
                          <Box sx={{ color: 'black.main', mr: 1 }}>
                            <CircleIcon sx={{ fontSize: '6px' }} />
                          </Box>
                          <Typography
                            sx={{
                              color: 'gray.800',
                              fontWeight: '500',
                              fontSize: 13
                            }}>
                            {customTitleCase(subtopic)}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const renderListCellExpand = (params: GridRenderCellParams) => {
  const { field } = params;
  return (
    <GridCellExpand width={params.colDef.computedWidth} unformattedValue={params.row[field]} />
  );
};

export default renderListCellExpand;
