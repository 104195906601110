const styles = {
  textAlignment: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  dateText: {
    color: 'black.main',
    fontWeight: '400',
    fontSize: 14
  },
  allUpdates: {
    fontSize: 13,
    cursor: 'pointer',
    color: 'gray.700',
    fontWeight: '500',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationSkipInk: 'none',
    textDecorationThickness: 'auto',
    textUnderlineOffset: 'auto',
    textUnderlinePosition: 'from-font'
  },
  mainDrawer: {
    '& .MuiDrawer-paper': {
      right: 0,
      width: 700,
      maxWidth: 700,
      height: '100%',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      overflow: 'hidden',
      backgroundColor: 'gray.50'
    }
  },
  drawerTitle: {
    maxWidth: '75%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700
  },
  crossIcon: { ml: 'auto', p: 0.75, mr: -0.75 },
  drawerHeaderIcon: { fontSize: 16, color: 'white.main' },
  headingText: {
    color: 'gray.900',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '160%'
  },
  divider: { mt: 2, width: '100%' },
  infoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    borderRadius: 3,
    backgroundColor: 'white.main',
    px: 2.5,
    py: 2.5,
    mx: 2.5
  },
  loadingIcon: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
};

export default styles;
