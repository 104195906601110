import React, { useState, useMemo, useEffect, useRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { SearchIcon } from '../../assets/svgs/Icons';
import { BottomSearchKeywordOptionsProps } from './types';
import { bottomSearchKeywordOptionsStyles } from './styles';

const BottomSearchKeywordOptions = ({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  setPreviousSelectedCategoryOptions,
  placeholderText,
  handleSearchKeyword
}: BottomSearchKeywordOptionsProps) => {
  const [searchText, setSearchText] = useState('');
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  // remove the search text from input box when another fitler is applied
  useEffect(() => {
    setSearchText('');
  }, [selectedCategoryOptions]);

  const handleAPIRequest = (text: string) => {
    // Cancel the previous request if it exists
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
    }
    // Create a new cancel token source
    cancelTokenSourceRef.current = axios.CancelToken.source();

    if (text.length > 0) {
      const formattedFilters = {
        ...selectedCategoryOptions,
        filter_keywords: [...(selectedCategoryOptions?.filter_keywords ?? []), text]
      };
      handleSearchKeyword(formattedFilters, cancelTokenSourceRef.current.token);
    } else if (text.length === 0) {
      handleSearchKeyword({ ...selectedCategoryOptions }, cancelTokenSourceRef.current.token);
    }
  };

  const debouncedHandleAPIRequest = useMemo(
    () =>
      debounce((text: string) => {
        handleAPIRequest(text);
      }, 500),
    [selectedCategoryOptions]
  );

  const handleSearch = (
    event: React.KeyboardEvent<HTMLDivElement>,
    isSearchIconClicked: boolean
  ) => {
    if (
      (event.key === 'Enter' || isSearchIconClicked) &&
      searchText &&
      !selectedCategoryOptions?.filter_keywords?.includes(searchText)
    ) {
      let formattedFilters: any = {};
      if (selectedCategoryOptions?.filter_keywords) {
        formattedFilters = {
          ...selectedCategoryOptions,
          filter_keywords: [searchText, ...selectedCategoryOptions.filter_keywords]
        };
      } else {
        formattedFilters = { ...selectedCategoryOptions, filter_keywords: [searchText] };
      }

      setSelectedCategoryOptions(formattedFilters);
      setPreviousSelectedCategoryOptions(formattedFilters);
      setSearchText('');
    }
  };

  const handleSearchChange = (searchedText: string) => {
    setSearchText(searchedText);
    debouncedHandleAPIRequest(searchedText);
  };

  return (
    <TextField
      placeholder={placeholderText}
      value={searchText}
      onChange={(ev: any) => handleSearchChange(ev.target.value)}
      onKeyDown={ev => handleSearch(ev, false)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon
              onClick={(ev: any) => handleSearch(ev, true)}
              sx={bottomSearchKeywordOptionsStyles.searchIcon}
            />
          </InputAdornment>
        ),
        sx: { ...bottomSearchKeywordOptionsStyles.inputProps }
      }}
      sx={bottomSearchKeywordOptionsStyles.textField}
    />
  );
};

export default React.memo(BottomSearchKeywordOptions);
