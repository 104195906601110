import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Button, Divider, Popover, Tooltip } from '@mui/material';

// hooks
import useMHRAGuidanceData from '../hooks/useMHRAGuidanceData';

// Store
import GlobalStore from '../../../store';
import store from '../../../store/MHRAGuidance';

// components
import MHRAGuidanceDetails from '../components/Details';
import ReportWidgets from '../../../components/Report/Widgets';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// utils
import CANADA_GUIDANCE_FILTERS from '../const';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import Insights from '../../../components/Insights/Insights';
import ComparisonIcon from '../../../assets/svgs/Icons/ComparisonIcon';
import actions from '../../../store/MHRAGuidance/actions';
import tabsStyles from '../styles/Tabs.styles';
import ComparisonDrawer from '../../../components/Comparison/ComparsionDrawer';
import ComparsionSelectionDialog from '../../../components/Comparison/CustomComparison/ComparsionSelectionDialog';
import getComparsionMetadata from '../utils/getComparsionMetadata';

const MHRAGuidanceTabs = () => {
  const {
    guidancefilters,
    isLoading,
    compareEnabled,
    documentsToCompare,
    comparePopupOpen,
    getMHRAGuidanceData,
    openComparisonPopup,
    clearComparison,
    clearSearch,
    sortFields,
    searchMHRAGuidanceData
  } = useMHRAGuidanceData();
  const { guidanceState, guidanceDispatch } = useContext(store);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const { dispatch } = useContext(GlobalStore) as any;
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [guidanceStats, setGuidanceStats] = useState<Stat[]>([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getMHRAGuidanceData([]);
  }, []);

  useEffect(() => {
    if (guidanceState?.guidanceStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: guidanceState?.guidanceStats?.totalGuidance || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: guidanceState?.guidanceStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: guidanceState?.guidanceStats?.pdfCount,
        value2: guidanceState?.guidanceStats?.pageCount
      });

      setGuidanceStats(newStats);
    }
  }, [guidancefilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      clearComparison();
      clearSearch();
      getMHRAGuidanceData(currentfilters);
    },
    [sortFields]
  );

  const handleSearchKeywordFilter = useCallback(
    (currentfilters: any, cancelToken: any) => {
      clearComparison();
      clearSearch();
      searchMHRAGuidanceData(currentfilters, cancelToken);
    },
    [sortFields]
  );

  const getFiltersLabelList = () => {
    return CANADA_GUIDANCE_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const getAriaDocuments = () => {
    const ariaDocuments = guidanceState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));

    return ariaDocuments || [];
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (guidanceState?.guidanceStats) {
      return [
        {
          text: 'Total Guidances',
          count: guidanceState?.guidanceStats?.totalGuidance,
          toolTipValue: ''
        },
        {
          text: 'Published in Last 30 Days',
          count: guidanceState?.guidanceStats?.totalLastThirtyDays,
          toolTipValue: ''
        },
        {
          text: 'Total Pages',
          count: truncateCountAboveMillions(guidanceState?.guidanceStats?.pageCount),
          toolTipValue: formatCount(guidanceState?.guidanceStats?.pageCount)
        }
      ];
    }
    return [];
  }, [guidanceState]);

  const getLayer1Charts = useMemo(() => {
    const guidanceGraph = VISUALIZE_CHART_MAPPING['mhra-guidance']?.layer1;

    if (guidanceGraph && guidanceState?.publishedDateBar) {
      const updatedGraph = guidanceGraph.map((graph: any) => ({
        ...graph,
        data: guidanceState?.publishedDateBar
      }));

      return updatedGraph;
    }
    return guidanceGraph;
  }, [guidanceState?.publishedDateBar]);

  const getLayer2Charts = useMemo(() => {
    const guidanceGraph = [...VISUALIZE_CHART_MAPPING['mhra-guidance'].layer2];
    if (guidanceGraph) {
      guidanceGraph.forEach(graph => {
        if (graph?.id === 'topics' && guidanceState?.donutCharts) {
          // eslint-disable-next-line no-param-reassign
          graph.data = guidanceState?.donutCharts[0]?.children;
        }
        if (graph?.id === 'subtopics' && guidanceState?.donutCharts) {
          // eslint-disable-next-line no-param-reassign
          graph.data = guidanceState?.donutCharts[1]?.children;
        }
      });

      return guidanceGraph;
    }
    return guidanceGraph;
  }, [guidanceState]);

  const getCompareList = useMemo(() => {
    return getComparsionMetadata(documentsToCompare);
  }, [documentsToCompare]);

  return (
    <>
      <SubHeader
        title='MHRA Guidelines'
        subTitle='Showing list of Guidelines published by MHRA'
        stats={guidanceStats}
        handleVisualizeOpen={handleVisualizeOpen}>
        <Button
          startIcon={<ComparisonIcon sx={tabsStyles.comparisonIcon} />}
          onClick={() => {
            guidanceDispatch({
              type: actions.SET_COMPARE_ENABLED,
              value: true
            });
          }}
          sx={tabsStyles.comparisonButton}>
          Compare Guidelines
        </Button>
        <Divider orientation='vertical' sx={tabsStyles.divider} />
      </SubHeader>

      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'mhra-guidance',
                moduleName: 'guidance',
                disableSelectionSource: true,
                filters: guidanceState.availableFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'mhra_guidance',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}

      {documentsToCompare.length === 2 && (
        <ComparsionSelectionDialog
          open={comparePopupOpen}
          source='mhra-guidance'
          title='Compare Guidelines'
          comparsionType='guidance'
          onClose={() => {
            guidanceDispatch({
              type: actions.SET_COMPARE_ENABLED,
              value: false
            });
            openComparisonPopup(false);
          }}
          compareList={getCompareList}
          showBackButton
          onBack={() => openComparisonPopup(false)}
        />
      )}

      <MHRAGuidanceDetails
        isFilterUpdated={isFilterUpdated}
        setIsFilterUpdated={setIsFilterUpdated}
      />
      <ComparisonDrawer
        open={guidanceState.compareEnabled}
        title='Compare Guidelines'
        onClose={() =>
          guidanceDispatch({
            type: actions.SET_COMPARE_ENABLED,
            value: false
          })
        }>
        <MHRAGuidanceDetails
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          isCheckboxSelectionEnabled
          isComparison={guidanceState.compareEnabled}
          openComparisonPopup={openComparisonPopup}
          getMHRAGuidanceData={getMHRAGuidanceData}
        />
      </ComparisonDrawer>

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='mhra-guidance'
          searchType='guidance'
          appliedFilter={guidanceState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={guidanceState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ uk: ['guidance'] }}
          groupId='entity_id'
          docCount={guidanceState?.guidanceStats?.pdfCount}
          docPageCount={guidanceState?.guidanceStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={guidancefilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={guidanceState.availableFilters}
        showSearchKeywordInBottomFilters
        source='mhra-guidance'
        handleSearchKeyword={handleSearchKeywordFilter}
      />

      <Tooltip title={compareEnabled ? 'Exit compare mode to search ARIA' : ''}>
        <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
          <AskRiaButton
            iconId='aria'
            setOpenAriaSearch={setAriaModalOpen}
            text='Ask RIA'
            disabled={compareEnabled || isLoading}
          />
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(MHRAGuidanceTabs);
