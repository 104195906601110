import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// api
import { fetchPDCOMeetings, fetchPDCOMeetingDetails } from '../../../api/pages/PDCO';
// utils
import getFilterOptions from '../../EMAMeetings/utils/common/getFilterOptions';
import useQuery from '../../../helpers/customHooks/queryParameter';
import { PDCO_FILTERS } from '../constants';

const usePDCOData = () => {
  const query: any = useQuery();
  const history = useHistory();
  const [allMeetingsList, setAllMeetingsList] = useState<any>({});
  const [meetingsList, setMeetingsList] = useState<any>({});
  const [meetingsStats, setMeetingsStats] = useState<any>({});
  const [meetingsListLoading, setMeetingsListLoading] = useState<boolean>(false);
  const [selectedMeeting, setSelectedMeeting] = useState<any>({});
  const [pdcoFilters, setPdcoFilters] = useState<any>([]);
  const [selectedResources, setSelectedResources] = useState<any>({});
  const [meetingDetailsLoading, setMeetingDetailsLoading] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied]: [boolean, Function] = useState(false);
  const [filtersApplied, setFilterApplied] = useState<any>({});
  const [meetingType, setMeetingType] = useState<'past' | 'upcoming'>('past');

  const groupResourcesByType = (resources: Array<Record<string, string>>) => {
    const meetingResources = resources.reduce((acc: any, item: any) => {
      const categoryBucket = item.category_bucket;
      const originalFileName = item?.s3_url.split('/').pop()?.split('?')[0];
      const modifiedItem = { ...item, originalFileName };
      if (!acc[categoryBucket]) {
        acc[categoryBucket] = [modifiedItem];
      } else {
        acc[categoryBucket].push(modifiedItem);
      }
      return acc;
    }, {});

    return meetingResources;
  };

  const getPDCOMeetings = async (
    appliedFilters: any = {},
    saveFilters: boolean = true,
    cancelToken: any = null
  ) => {
    try {
      setMeetingsListLoading(true);
      setMeetingDetailsLoading(true);

      if (saveFilters) {
        setIsFilterApplied(Object.keys(appliedFilters).length !== 0);
        setFilterApplied(appliedFilters);
      }

      const res = await fetchPDCOMeetings(appliedFilters, cancelToken);
      if (res?.status === 200) {
        setAllMeetingsList(res.data.body?.meetings?.meetings);
        setMeetingsStats(res.data.body?.stats);
        if (Object.keys(res.data.body?.meetings?.meetings).length > 0) {
          const filteredMeetings: { [year: string]: any[] } = {};

          Object.keys(res.data.body?.meetings?.meetings).forEach(year => {
            const completedMeetings = res.data.body?.meetings?.meetings[year].filter(
              (meeting: any) => meeting.meeting_type === meetingType
            );
            if (completedMeetings.length > 0) {
              filteredMeetings[year] = completedMeetings;
            }
          });

          setMeetingsList(filteredMeetings);
          const filters = res.data.body?.meetings?.filters;
          const filterOptions = getFilterOptions(PDCO_FILTERS, filters);
          setPdcoFilters(filterOptions);

          // select the latest meeting which have data also consider if user refreshes the page then same meeting year should be selected by default
          const sortedKeys = Object.keys(filteredMeetings)
            .map(key => parseInt(key, 10))
            .sort((a, b) => b - a);
          let tempSelectedMeeting: any = '';
          const selectedYear = parseInt(query.get('meetingId')?.split(' ')[1], 10);
          if (query.get('meetingId') && filteredMeetings[selectedYear]) {
            tempSelectedMeeting = filteredMeetings[selectedYear]?.find(
              (meeting: any) => meeting.meeting_id === query.get('meetingId')
            );
          } else {
            tempSelectedMeeting = filteredMeetings[sortedKeys[0]]?.find(
              meeting => meeting?.total_document_count > 0
            );
          }

          const resMeetingDetails = await fetchPDCOMeetingDetails(
            tempSelectedMeeting?.meeting_id,
            appliedFilters,
            cancelToken
          );
          if (resMeetingDetails?.status === 200) {
            if (Object.keys(resMeetingDetails.data.body?.sections).length !== 0) {
              setSelectedMeeting({
                sections: resMeetingDetails.data.body?.sections,
                meeting_stats: resMeetingDetails.data.body?.meeting_stats
              });
            } else {
              setSelectedMeeting({
                sections: {},
                meeting_stats: resMeetingDetails.data.body?.meeting_stats
              });
            }
            // Set  meeting level documents
            const documentResources = resMeetingDetails.data.body?.meeting_stats?.documents;
            const documentResourcesMapped = groupResourcesByType(documentResources);
            setSelectedResources(documentResourcesMapped);
            const queryParams = new URLSearchParams();
            queryParams.set('meetingId', tempSelectedMeeting?.meeting_id);
            history.push(`?${queryParams.toString()}`);
          } else {
            setSelectedMeeting({});
            history.push('/pdco');
          }
        } else {
          setMeetingsList({});
          setSelectedMeeting({});
          history.push('/pdco');
        }
      }
      setMeetingType('past');
    } catch (err) {
      setMeetingsList({});
      setSelectedMeeting({});
      console.error(err);
    } finally {
      setMeetingsListLoading(false);
      setMeetingDetailsLoading(false);
    }
  };

  const handleSelectMeeting = async (meetingId: string) => {
    try {
      setMeetingDetailsLoading(true);
      const resMeetingDetails = await fetchPDCOMeetingDetails(meetingId, filtersApplied);
      if (resMeetingDetails?.status === 200) {
        if (Object.keys(resMeetingDetails.data.body?.sections).length !== 0) {
          setSelectedMeeting({
            sections: resMeetingDetails.data.body?.sections,
            meeting_stats: resMeetingDetails.data.body?.meeting_stats
          });
        } else {
          setSelectedMeeting({
            sections: {},
            meeting_stats: resMeetingDetails.data.body?.meeting_stats
          });
        }
        // Set  meeting level documents
        const documentResources = resMeetingDetails.data.body?.meeting_stats?.documents;
        const documentResourcesMapped = groupResourcesByType(documentResources);
        setSelectedResources(documentResourcesMapped);
        const queryParams = new URLSearchParams();
        queryParams.set('meetingId', meetingId);
        // Update the URL with the query parameters
        history.push(`?${queryParams.toString()}`);
      } else {
        setSelectedMeeting({});
        history.push('/pdco');
      }
    } catch (err) {
      setSelectedMeeting({});
      history.push('/pdco');
      console.error(err);
    } finally {
      setMeetingDetailsLoading(false);
    }
  };

  const handleSelectMeetingType = async (selectedType: 'past' | 'upcoming') => {
    try {
      setMeetingsListLoading(true);
      setMeetingDetailsLoading(true);
      setMeetingType(selectedType);
      if (Object.keys(allMeetingsList).length > 0) {
        const filteredMeetings: { [year: string]: any[] } = {};

        Object.keys(allMeetingsList).forEach(year => {
          const completedMeetings = allMeetingsList[year].filter(
            (meeting: any) => meeting.meeting_type === selectedType
          );
          if (completedMeetings.length > 0) {
            filteredMeetings[year] = completedMeetings;
          }
        });

        setMeetingsList(filteredMeetings);

        const sortedKeys = Object.keys(filteredMeetings)
          .map(key => parseInt(key, 10))
          .sort((a, b) => b - a);
        const tempSelectedMeeting = filteredMeetings[sortedKeys[0]][0];
        const resMeetingDetails = await fetchPDCOMeetingDetails(
          tempSelectedMeeting?.meeting_id,
          {}
        );
        if (resMeetingDetails?.status === 200) {
          if (Object.keys(resMeetingDetails.data.body?.sections).length !== 0) {
            setSelectedMeeting({
              sections: resMeetingDetails.data.body?.sections,
              meeting_stats: resMeetingDetails.data.body?.meeting_stats
            });
          } else {
            setSelectedMeeting({
              sections: {},
              meeting_stats: resMeetingDetails.data.body?.meeting_stats
            });
          }
          // Set meeting level documents
          const documentResources = resMeetingDetails.data.body?.meeting_stats?.documents;
          const documentResourcesMapped = groupResourcesByType(documentResources);
          setSelectedResources(documentResourcesMapped);
          const queryParams = new URLSearchParams();
          queryParams.set('meetingId', tempSelectedMeeting?.meeting_id);
          history.push(`?${queryParams.toString()}`);
        } else {
          setSelectedMeeting({});
          history.push('/pdco');
        }
      } else {
        setMeetingsList({});
        setSelectedMeeting({});
        history.push('/pdco');
      }
    } catch (err) {
      setMeetingsList({});
      setSelectedMeeting({});
      console.error(err);
    } finally {
      setMeetingsListLoading(false);
      setMeetingDetailsLoading(false);
    }
  };

  return {
    getPDCOMeetings,
    meetingsList,
    meetingsStats,
    selectedMeeting,
    pdcoFilters,
    selectedResources,
    setSelectedMeeting,
    handleSelectMeeting,
    isFilterApplied,
    meetingsListLoading,
    meetingDetailsLoading,
    meetingType,
    handleSelectMeetingType
  };
};

export default usePDCOData;
