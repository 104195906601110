import { useState } from 'react';
import { getUpdatesApi } from '../../../api/pages/MHRAGuidance';

const useAllUpdates = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updates, setUpdates] = useState([]);
  const getUpdates = async (guidanceId: string) => {
    setLoading(true);
    const res = await getUpdatesApi(guidanceId)
      .catch(err => setError(err))
      .finally(() => setLoading(false));

    if (res?.status === 200 && res?.data?.body[0]?.change_history) {
      setUpdates(res?.data?.body[0]?.change_history || []);
      setError(null);
    } else {
      setUpdates([]);
      setError(res?.data?.message || 'An error occurred');
    }
  };

  return {
    loading,
    error,
    updates,
    getUpdates
  };
};

export default useAllUpdates;
