import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import CloseCrossIcon from '../../../assets/svgs/Icons/CloseCrossIcon';
import styles from '../styles/AllUpdates.styles';
import getDateString from '../../../utils/getDateString';
import useAllUpdates from '../hooks/useAllUpdates';

const Section = ({ updatedDate, updateNotes }: { updatedDate: string; updateNotes: string }) => {
  return (
    <Stack direction='row' spacing={4}>
      <Typography
        sx={{
          color: 'gray.700',
          fontSize: 14,
          fontWeight: 500,
          minWidth: 152
        }}>
        {getDateString(updatedDate)}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: 'gray.900'
        }}>
        {updateNotes}
      </Typography>
    </Stack>
  );
};
const AllUpdatesDrawer = ({
  openDrawer,
  onClose,
  guidanceId,
  title
}: {
  openDrawer: boolean;
  onClose: () => void;
  guidanceId: any;
  title: string;
}) => {
  const { loading, updates, getUpdates } = useAllUpdates();

  useEffect(() => {
    if (guidanceId) {
      getUpdates(guidanceId);
    }
  }, [guidanceId]);

  return (
    <Drawer
      open={openDrawer}
      anchor='right'
      transitionDuration={500}
      hideBackdrop={false}
      sx={styles.mainDrawer}
      onClose={onClose}>
      <Box bgcolor='primary.600' py={1.5} px={2.5}>
        <Stack direction='row' alignItems='center'>
          <Tooltip title={title}>
            <Typography sx={styles.drawerTitle}>{title}</Typography>
          </Tooltip>
          <IconButton sx={styles.crossIcon} onClick={onClose}>
            <CloseCrossIcon sx={styles.drawerHeaderIcon} />
          </IconButton>
        </Stack>
      </Box>
      {!loading && updates.length > 0 && (
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100% - 70px)'
          }}>
          <Box px={2.5} py={2}>
            <Typography sx={styles.headingText}>All Updates</Typography>
          </Box>
          <Box gap={2} sx={styles.infoContainer}>
            {updates.map((update: any) => (
              <Stack spacing={2} key={update.date} width='100%'>
                <Section updatedDate={update.date} updateNotes={update.change_text} />
                <Divider sx={styles.divider} />
              </Stack>
            ))}
          </Box>
        </Box>
      )}
      {loading && (
        <Box sx={styles.loadingIcon}>
          <CircularProgress />
        </Box>
      )}
      {!loading && !updates.length && (
        <Box sx={styles.loadingIcon}>
          <Typography fontSize={14}>There are no updates history available.</Typography>
        </Box>
      )}
    </Drawer>
  );
};

export default React.memo(AllUpdatesDrawer);
