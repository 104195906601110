import React from 'react';
import { createSvgIcon } from '@mui/material';

const ArrowDropDownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5' fill='none'>
    <path
      d='M0.333984 0H9.66732L5.47198 4.19533C5.34697 4.32031 5.17743 4.39052 5.00065 4.39052C4.82387 4.39052 4.65434 4.32031 4.52932 4.19533L0.333984 0Z'
      fill='#757575'
    />
  </svg>,
  'AngleRight'
);

export default ArrowDropDownIcon;
