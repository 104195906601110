import React from 'react';
import { Drawer, Stack, Typography, Tooltip, IconButton } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import styles from './styles/ComparisonDrawer';

const ComparisonDrawer = ({ open, title = 'Compare Guidelines', onClose, children }: any) => {
  return (
    <Drawer open={open} anchor='bottom' onClose={onClose} PaperProps={styles.drawerPaperPropsStyle}>
      <Stack direction='row' sx={styles.headerWrapper}>
        <Stack spacing={1} sx={styles.guidanceComparisonContainer}>
          <Typography sx={styles.guidanceComparisonText}>{`${title} (Limit: 2 Docs)`}</Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton onClick={onClose} size='small' sx={styles.iconButton}>
            <HighlightOffOutlinedIcon fontSize='inherit' sx={styles.cancelIcon} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack>{children}</Stack>
    </Drawer>
  );
};

export default React.memo(ComparisonDrawer);
