import getDateString from '../../../utils/getDateString';

const getComparsionMetadata = (documentList: Array<any>) => {
  if (documentList?.length !== 0) {
    const compareList: Array<any> = [];
    documentList.forEach(doc => {
      const docObject: any = {};
      docObject.title = doc?.title;
      docObject.source = 'mhra';
      docObject.documentList = doc?.documents
        ?.sort((a: any, b: any) => (a?.document_type || '').localeCompare(b?.document_type || ''))
        .map((item: any, index: number) => {
          const isGuidance = ['guideline (latest)'].some(keyword =>
            item?.document_type?.toLowerCase().includes(keyword)
          );
          const style = {
            color: isGuidance ? '#0076D6FF' : 'inherit', // The color code is added because it is used in the span element's style
            fontWeight: isGuidance ? 600 : 'inherit'
          };
          return {
            metadata: [
              {
                id: index + 1,
                label: 'Document Date',
                value: item?.document_date ? getDateString(item?.document_date) : ''
              },
              {
                id: index + 1,
                label: 'Document Type',
                value: item?.document_type,
                style
              }
            ],
            s3_path: item?.s3_path,
            title: item?.document_title,
            document_date: item?.document_date,
            id: item?.id
          };
        });
      compareList.push(docObject);
    });

    return compareList;
  }
  return [];
};

export default getComparsionMetadata;
