import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  Popover,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Virtuoso } from 'react-virtuoso';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { autoSearchOptionsStyles, styles } from './styles';
import { OptionsProps } from './types';
import OptionFooter from './OptionFooter';
import { isObject } from './utils';
import VirtuosoCustomScrollbar from '../VirtuosoCustomScrollbar/VirtuosoCustomScrollbar';
import CustomCheckbox from '../CustomComponents/Checkbox';
import { ArrowDropRightIcon } from '../../assets/svgs/Icons';

const AutoSearchOptions = ({
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  handleClose,
  applyFilters,
  isLoading,
  setShowFilterSidebar,
  previousSelectedCategoryOptions,
  handleApplyClose
}: OptionsProps) => {
  const [searchText, setSearchText] = useState('');
  const [options, setOptions]: any = useState([]);
  const [subCategories, setSubCategories] = useState<Record<string, string[]>>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLLabelElement | null>(null);
  const [isLabelClicked, setIsLabelClicked] = React.useState<boolean>(false);

  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  useEffect(() => {
    setOptions(selectedCategory.options);
  }, [selectedCategory]);

  const handleSearch = (value: string) => {
    const searchedText = value.toLowerCase();
    setSearchText(value);

    const formattedOptions = selectedCategory.options.filter((option: any) => {
      // Start by creating a list of lowercase strings to compare against `searchText`
      let selectedOptionStrings: string[] = [];

      // If `option` is an object, grab `label` and possibly `subtopics`
      if (isObject(option)) {
        if (option?.subtopics) {
          selectedOptionStrings = [
            option.label.toLowerCase(),
            ...option.subtopics.map((subtopic: string) => subtopic.toLowerCase())
          ];
        } else {
          // Make sure to correct the `.toLowerCase()` typo
          selectedOptionStrings = [option.label.toLowerCase()];
        }
      } else {
        // If option is just a string, push it as a single-element array
        selectedOptionStrings = [option.toLowerCase()];
      }

      // Check if any of the strings in selectedOptionStrings includes `searchText`
      return selectedOptionStrings.some(str => str.includes(searchedText));
    });

    setOptions(formattedOptions);
  };

  const handleCheckbox = (value: any) => {
    const subtopics = value?.subtopics;
    const hasSubtopicsArray = Array.isArray(subtopics);
    const currentCategoryOptions = selectedCategoryOptions[selectedCategoryValueKey];
    const totalSelectedKeys = Object.keys(selectedCategoryOptions).length;

    // If value has subtopics as an array, handle it as a "grouped" checkbox
    if (subtopics && hasSubtopicsArray) {
      const isSubTopicLabelAlreadySelected = currentCategoryOptions?.some(
        (each: { label: string; subtopics: string[] }) => each?.label === value?.label
      );
      // If there's already an entry for the current category key, remove it; otherwise, add it
      if (isSubTopicLabelAlreadySelected) {
        const remainingTopics = currentCategoryOptions?.filter(
          (each: { label: string; subtopics: string[] }) => each?.label !== value?.label
        );
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: remainingTopics
        });
      } else {
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: [
            ...(selectedCategoryOptions[selectedCategoryValueKey] ?? []),
            { label: value?.label, subtopics }
          ]
        });
      }
      return;
    }

    const updatedValue = isObject(value) ? value?.label : value;
    // For a "single" checkbox (no subtopics)
    const valueExists =
      currentCategoryOptions?.includes(updatedValue) ||
      currentCategoryOptions
        ?.map((item: any) => item.toLowerCase())
        ?.includes(updatedValue?.toLowerCase());

    // If value already exists for this category, remove it; otherwise, add it
    if (totalSelectedKeys > 0 && valueExists) {
      const filteredOptions = currentCategoryOptions.filter(
        (option: any) => option.toLowerCase() !== updatedValue?.toLowerCase()
      );

      // If there are still other values left after removal, update them; otherwise, remove the key
      if (filteredOptions.length) {
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: filteredOptions
        });
      } else {
        const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
        setSelectedCategoryOptions(others);
      }
    } else {
      // Add value under this category key (append if it already exists, otherwise create a new array)
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]:
          totalSelectedKeys > 0 && currentCategoryOptions
            ? [...currentCategoryOptions, updatedValue]
            : [updatedValue]
      });
    }
  };

  const handleSelectAll = () => {
    const formattedOptions = options.map((each: any) =>
      isObject(each) && !each?.subtopics ? each?.label : each
    );
    const isAllCategoryOptionsSelected =
      Object.keys(selectedCategoryOptions)?.length > 0 &&
      selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length;
    if (!isAllCategoryOptionsSelected) {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: formattedOptions
      });
    } else {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    }
  };

  const isChecked = (each: any) => {
    return (
      Object.keys(selectedCategoryOptions).length > 0 &&
      selectedCategoryOptions[selectedCategoryValueKey]
        ?.map((item: any) => (isObject(item) ? item?.label?.toLowerCase() : item?.toLowerCase()))
        ?.includes(isObject(each) ? each.label?.toLowerCase() : each?.toLowerCase())
    );
  };

  const isSubCategoryChecked = (
    selectedCategoryOptionLabel: string,
    selectedCategoryOptionSubLabel: string
  ) => {
    // 1. Check if there are any selected options at all
    if (Object.keys(selectedCategoryOptions).length === 0) {
      return false;
    }

    // 2. Get the list of topics (if any) for the current category key
    const currentCategoryOptions = selectedCategoryOptions[selectedCategoryValueKey];
    if (!currentCategoryOptions) {
      return false;
    }

    // 3. Find the matching topic by label
    const matchingTopic = currentCategoryOptions.find(
      (topic: Record<string, any>) =>
        topic.label?.toLowerCase() === selectedCategoryOptionLabel?.toLowerCase()
    );
    if (!matchingTopic?.subtopics) {
      return false;
    }

    // 4. Compare subtopics ignoring case
    const subtopicsInLowerCase = matchingTopic.subtopics.map((subTopic: string) =>
      subTopic?.toLowerCase()
    );

    return subtopicsInLowerCase.includes(selectedCategoryOptionSubLabel?.toLowerCase());
  };

  const handleSubCategoryCheckbox = (
    selectedCategoryOptionLabel: string,
    selectedCategoryOptionSubLabel: string
  ) => {
    const currentCategoryOptions = selectedCategoryOptions[selectedCategoryValueKey] || [];

    // Find the category by label (case-insensitive)
    const formattedCurrentCategoryOptions = currentCategoryOptions.find(
      (topic: { label: string; subtopics: string[] }) =>
        topic?.label?.toLowerCase() === selectedCategoryOptionLabel?.toLowerCase()
    );

    // Filter out the subtopic that matches the selected subtopic (case-insensitive)
    const remainingSubtopics =
      formattedCurrentCategoryOptions?.subtopics?.filter(
        (subTopic: string) =>
          subTopic?.toLowerCase() !== selectedCategoryOptionSubLabel?.toLowerCase()
      ) || [];

    // Remove the category that matches the selected label (case-insensitive)
    const remainingCategories = currentCategoryOptions.filter(
      (topic: { label: string; subtopics: string[] }) =>
        topic?.label?.toLowerCase() !== selectedCategoryOptionLabel?.toLowerCase()
    );

    // Check if the subcategory is already selected
    const subCategoryChecked = isSubCategoryChecked(
      selectedCategoryOptionLabel,
      selectedCategoryOptionSubLabel
    );

    if (subCategoryChecked) {
      if (remainingSubtopics.length > 0) {
        // Update the category with remaining subtopics
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: [
            ...remainingCategories,
            {
              label: selectedCategoryOptionLabel,
              subtopics: remainingSubtopics
            }
          ]
        });
      } else if (remainingCategories.length > 0) {
        // Remove the entire category if it has no remaining subtopics
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: remainingCategories
        });
      } else {
        // Remove the category key if no categories remain
        const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
        setSelectedCategoryOptions(others);
      }
    } else {
      // Add the subcategory to the selected options
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: [
          ...remainingCategories,
          {
            label: selectedCategoryOptionLabel,
            subtopics: [...remainingSubtopics, selectedCategoryOptionSubLabel]
          }
        ]
      });
    }
  };

  const isCategoryCheckboxIntermediate = (checkedData: { label: string; subtopics: string[] }) => {
    if (checkedData?.subtopics && Array.isArray(checkedData?.subtopics)) {
      const selectedSubTopicCategory =
        !!selectedCategoryOptions[selectedCategoryValueKey] &&
        selectedCategoryOptions[selectedCategoryValueKey]?.find(
          (each: { label: string; subtopics: string[] }) => each?.label === checkedData?.label
        );

      return (
        selectedSubTopicCategory?.subtopics &&
        selectedSubTopicCategory?.subtopics?.length > 0 &&
        selectedSubTopicCategory?.subtopics?.length !== checkedData?.subtopics?.length
      );
    }
    return false;
  };

  const labelText = (each: any) => {
    // If 'each' isn't an object, just return it as-is.
    if (!isObject(each)) {
      return each;
    }

    // If 'each' is an object and has a 'count', format "label (count)".
    // Otherwise, return just "label".
    return each.count ? `${each.label} (${each.count})` : each.label;
  };

  return (
    <>
      <Box width={295} height={321}>
        <Box height={253}>
          <Box mb={1} sx={styles.header} onClick={handleClose}>
            <Button sx={styles.closeIconButton}>
              <CloseIcon sx={{ ...styles.icon, fontSize: 20 }} />
            </Button>
          </Box>
          <Box ml={3}>
            <TextField
              size='small'
              value={searchText}
              onChange={ev => handleSearch(ev.target.value)}
              placeholder={`Search ${selectedCategory.label}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon sx={styles.icon} />
                  </InputAdornment>
                ),
                sx: autoSearchOptionsStyles.searchInput
              }}
            />
            <Box display='flex' justifyContent='flex-end'>
              {options?.length > 0 && (
                <Button
                  sx={{
                    fontSize: 12,
                    fontWeight: 600,
                    textTransform: 'none',
                    textDecorationLine: 'underline',
                    color: 'primary.600',
                    mr: 2.25
                  }}
                  onClick={handleSelectAll}>
                  {Object.keys(selectedCategoryOptions)?.length > 0 &&
                  selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length
                    ? 'Deselect all'
                    : 'Select all'}
                </Button>
              )}
            </Box>
            <Box sx={{ ...styles.scrollContainer, ...autoSearchOptionsStyles.content }}>
              <Virtuoso
                components={{ Scroller: VirtuosoCustomScrollbar }}
                overscan={600}
                totalCount={options?.length}
                data={options}
                // eslint-disable-next-line react/no-unstable-nested-components
                itemContent={(_index, each) => (
                  <Box
                    key={isObject(each) ? each.label : each}
                    display='flex'
                    alignItems='center'
                    width='100%'
                    justifyContent='space-between'
                    py={0.75}>
                    <FormControlLabel
                      onClick={(ev: React.MouseEvent<HTMLLabelElement>) => {
                        if (each?.subtopics) {
                          ev.preventDefault();
                          handleCheckbox(each);
                          setSubCategories({ [each?.label]: each?.subtopics });
                          setAnchorEl(ev.currentTarget);
                          setIsLabelClicked(true);
                        }
                      }}
                      control={
                        <CustomCheckbox
                          checked={isChecked(each)}
                          indeterminate={isCategoryCheckboxIntermediate(each)}
                          size='small'
                          value={isChecked(each)}
                          onChange={() => handleCheckbox(each)}
                          onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
                            ev.stopPropagation()
                          }
                          style={{ color: isChecked(each) ? '#0D917D' : '#424242' }}
                          sx={{ p: 0, mr: 1.5 }}
                        />
                      }
                      label={labelText(each)}
                      sx={{
                        wordBreak: 'break-word',
                        color: 'gray.800',
                        ml: 0,
                        mr: 0,
                        '& .MuiFormControlLabel-label': { fontSize: 14, color: 'gray.800' }
                      }}
                    />
                    {each?.subtopics && (
                      <Icon
                        sx={{ mr: 1.5, p: 0, alignItems: 'center', fontSize: 16 }}
                        onClick={(ev: React.MouseEvent<HTMLLabelElement>) => {
                          setSubCategories({ [each?.label]: each?.subtopics });
                          setAnchorEl(ev.currentTarget);
                        }}>
                        <ArrowDropRightIcon sx={{ cursor: 'pointer', mb: 0.625, fontSize: 10 }} />
                      </Icon>
                    )}
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box ml={2.3} mr={3} height={64} sx={{ ...styles.footer }}>
          <OptionFooter
            selectedCategory={selectedCategory}
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
            applyFilters={applyFilters}
            isLoading={isLoading}
            handleClose={handleClose}
            handleApplyClose={handleApplyClose}
            setShowFilterSidebar={setShowFilterSidebar}
            previousSelectedCategoryOptions={previousSelectedCategoryOptions}
          />
        </Box>
      </Box>
      {Object.keys(subCategories)?.length > 0 && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'left', vertical: 'center' }}
          sx={{ ml: isLabelClicked ? 13 : 2, width: '92%' }}
          onClose={() => {
            setSubCategories({});
            setAnchorEl(null);
            setIsLabelClicked(false);
          }}>
          <Box py={1.5} pl={3} pr={4}>
            {Object.entries(subCategories)?.map(([subCategoryLabel, subCategoryValues]) =>
              subCategoryValues?.map((subCategory: string) => (
                <Box key={subCategoryLabel} display='flex' py={0.75} rowGap={0.75}>
                  <CustomCheckbox
                    checked={isSubCategoryChecked(subCategoryLabel, subCategory)}
                    onChange={() => handleSubCategoryCheckbox(subCategoryLabel, subCategory)}
                    size='small'
                    style={{
                      color: isSubCategoryChecked(subCategoryLabel, subCategory)
                        ? '#0D917D'
                        : '#424242'
                    }}
                    sx={{ p: 0, pr: 1.5 }}
                  />
                  <Tooltip title={subCategory}>
                    <Typography sx={styles.threeLineText} color='black.main' fontSize={14}>
                      {subCategory}
                    </Typography>
                  </Tooltip>
                </Box>
              ))
            )}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default AutoSearchOptions;
