/* eslint-disable no-underscore-dangle */
import { Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import styles from '../styles/GuidelinesList.styles';
import GuidelinesCards from '../../Guidelines/components/common/GuidelinesCards';
import GuidelinesActionsDetails from './GuidelinesActionsDetails';
import { GUDELINE_TITLE_MAPPING } from '../contants';

import Documents from './Documents/Documents';
import { DocumentDetail, GuidelinesListProps } from '../types';
import { getCardMetadataList } from '../utils';

const GuidelinesList: React.FC<GuidelinesListProps> = ({
  category,
  guidelineData,
  onSectionExpand,
  expanded
}) => {
  const [documentDetails, setDocumentDetails] = useState<DocumentDetail | Record<string, never>>(
    {}
  );

  const ICHMetadata = useMemo(
    () => (selectedData: any) => {
      const metadataList = getCardMetadataList(selectedData);
      return metadataList;
    },
    []
  );

  const getSortedData = useMemo(
    () => (data: any) => {
      const sortedData = data.sort((a: any, b: any) => {
        const cleanIdA = a._id.replace(/[^a-zA-Z0-9]/g, '');
        const cleanIdB = b._id.replace(/[^a-zA-Z0-9]/g, '');

        return cleanIdA.localeCompare(cleanIdB, undefined, { numeric: true });
      });
      return sortedData;
    },
    []
  );

  return (
    <>
      <Stack spacing={1} id={category} sx={{ ...styles.guidelinesContainer, pb: 3 }}>
        <Stack spacing={1} aria-label='section' margin={0}>
          <Typography sx={styles.categoryText}>{category}</Typography>
        </Stack>
        {Object.keys(guidelineData)
          ?.sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)?.[0] || '0', 10);
            const numB = parseInt(b.match(/\d+/)?.[0] || '0', 10);
            return numA - numB;
          })
          ?.map(item => (
            <Stack id={item} key={item} spacing={1} margin={0} width='100%' gap={2.5}>
              <Typography sx={styles.subHeadingText}>
                {GUDELINE_TITLE_MAPPING[item] || ''}
              </Typography>
              {getSortedData(guidelineData[item])?.map((data: any) => {
                const metadataList = ICHMetadata(data);
                return (
                  <GuidelinesCards
                    // eslint-disable-next-line no-underscore-dangle
                    key={data?._id}
                    title={`${data?._id}: ${data?.title}`}
                    data={data}
                    setDocumentDetails={setDocumentDetails}
                    GuidelinesDetails={GuidelinesActionsDetails}
                    metadataList={metadataList}
                    isExpanded={
                      // eslint-disable-next-line no-underscore-dangle
                      expanded === data?._id
                    }
                    onSectionExpand={onSectionExpand}
                  />
                );
              })}
            </Stack>
          ))}
      </Stack>
      {Object.keys(documentDetails).length > 0 && (
        <Documents
          documentDetails={documentDetails}
          setDocumentDetails={setDocumentDetails}
          partyName=''
        />
      )}
    </>
  );
};

export default React.memo(GuidelinesList);
