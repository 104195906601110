const styles = {
  mainHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px 8px 0px 0px',
    px: 1.25,
    py: 2,
    backgroundColor: 'gray.200',
    height: 48,
    alignItems: 'center'
  },
  compareBox: {
    backgroundColor: 'white.main',
    height: '90%',
    position: 'relative',
    border: '1px solid',
    borderColor: 'gray.200'
  },
  flagIcon: { width: 18, height: 12 },
  heading: {
    fontFamily: 'Mulish',
    fontSize: 15,
    color: 'gray.900',
    fontWeight: 700,
    maxWidth: 520,
    cursor: 'default',
    lineHeight: 'normal',
    height: 18,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  searchBarWrapper: {
    padding: '10px 8px 10px 16px',
    backgroundColor: 'gray.50',
    borderBottom: '1px solid',
    borderColor: 'gray.200'
  },
  searchBarTextField: {
    '& .MuiInputBase-root': {
      borderRadius: 8,
      height: 28,
      width: 260,
      backgroundColor: 'white.main'
    }
  },
  mainSectionWrapper: {
    px: 4,
    pt: 2,
    overflowY: 'auto',
    height: '90%',
    position: 'relative',
    minHeight: 'auto'
  },
  searchIcon: { height: 14, width: 14 },
  tempCtaContainer: { height: 24, alignItems: 'center' },
  sortIconButton: { minWidth: 'auto' },
  sortIcon: { height: 14, width: 14, color: 'gray.600' },
  divider: { mx: 1, color: 'gray.200' },
  documentContainer: {
    height: 58,
    display: 'flex',
    cursor: 'pointer',
    gap: '3px'
  },
  documentContainerSelected: {
    cursor: 'pointer',
    display: 'flex',
    height: 58,
    gap: '3px'
  },
  radio: {
    p: 0,
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  subHeading: {
    color: 'gray.900',
    fontFamily: 'Mulish',
    fontSize: 13,
    fontWeight: 700,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    maxWidth: 500
  },
  bottomDivider: { mt: 1.75, mb: 2 },
  metadata: {
    display: 'flex',
    color: 'gray.600',
    fontFamily: 'Mulish',
    fontSize: 13,
    fontWeight: 400
  }
};
export default styles;
