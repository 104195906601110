import React, { useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Divider,
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import styles from './styles/ComparsionSectionList.styles';
import {
  sourceFlagMapping,
  sourceFlagMappingTooltip
} from '../../../pages/SearchResults/constants';
import MagnifyingGlassIcon from '../../../assets/svgs/Icons/MagnifyingGlassIcon';
import { SortIconVariant } from '../../../assets/svgs/Icons';
import { ComparsionMetadataProp, ComparsionSectionListProps, DocumentListProps } from './type';

const ComparsionSectionList = ({
  data,
  onSelectDocument,
  selectedDocument = {}
}: ComparsionSectionListProps) => {
  const FlagComponent = sourceFlagMapping[data?.source?.toLowerCase()];
  const flagTooltipTitle = sourceFlagMappingTooltip[data?.source?.toLowerCase()];
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [oldestTONewest, setOldestTONewest] = useState(false);

  const handleSortBy = () => {
    setOldestTONewest((prev: boolean) => {
      return !prev;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchQuery(e.target.value);
  };

  const documentListToShow = useMemo(() => {
    if (!data?.documentList?.length) return [];
    const query = localSearchQuery?.trim()?.toLowerCase();
    // filter the document list if search or sort is applied
    return data?.documentList
      .filter((doc: DocumentListProps) => {
        if (!query) return true;
        const metadataMatch = doc?.metadata?.some((meta: { value: string }) =>
          meta.value?.toLowerCase().includes(query)
        );
        const titleMatch = doc.title?.toLowerCase().includes(query);

        return metadataMatch || titleMatch;
      })
      .sort((a: any, b: any) => {
        const dateA = a.document_date ? new Date(a.document_date).getTime() : 0;
        const dateB = b.document_date ? new Date(b.document_date).getTime() : 0;
        return oldestTONewest ? dateA - dateB : dateB - dateA;
      });
  }, [data, localSearchQuery, oldestTONewest]);

  return (
    <>
      <Box sx={styles.mainHeaderWrapper}>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Tooltip title={data?.title}>
            <Typography sx={styles.heading}>{data?.title}</Typography>
          </Tooltip>
        </Stack>
        <Tooltip title={flagTooltipTitle}>
          <FlagComponent sx={styles.flagIcon} />
        </Tooltip>
      </Box>

      <Box sx={styles.compareBox}>
        <>
          <Stack direction='row' justifyContent='space-between' sx={styles.searchBarWrapper}>
            <TextField
              sx={styles.searchBarTextField}
              placeholder='Find Document'
              inputProps={{
                maxLength: 50,
                endAdornment: (
                  <InputAdornment position='end'>
                    <MagnifyingGlassIcon sx={styles.searchIcon} />
                  </InputAdornment>
                )
              }}
              onChange={handleSearchChange}
              value={localSearchQuery}
            />
            <Stack
              direction='row'
              divider={<Divider orientation='vertical' flexItem />}
              spacing={0.5}
              sx={styles.tempCtaContainer}>
              <Tooltip
                title={`Sort by ${oldestTONewest ? 'Newest to Oldest' : 'Oldest to Newest'}`}>
                <Button onClick={handleSortBy} sx={styles.sortIconButton}>
                  <Badge variant='dot' invisible={!oldestTONewest} color='primary'>
                    <SortIconVariant sx={styles.sortIcon} />
                  </Badge>
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
          <Box sx={styles.mainSectionWrapper}>
            {documentListToShow?.map((item: any) => {
              return (
                <React.Fragment key={item?.id}>
                  <Box
                    sx={
                      selectedDocument?.id === item?.id
                        ? styles.documentContainerSelected
                        : styles.documentContainer
                    }>
                    <Box mr='9px'>
                      <Radio
                        size='small'
                        checked={selectedDocument?.id === item?.id}
                        sx={styles.radio}
                        onClick={() => {
                          onSelectDocument(item);
                        }}
                      />
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      onClick={() => {
                        onSelectDocument(item);
                      }}>
                      <Box display='flex' alignItems='center'>
                        <Tooltip title={item?.title}>
                          <Typography
                            sx={{
                              ...styles.subHeading,
                              ...(selectedDocument?.id === item?.id && { color: 'primary.700' })
                            }}>
                            {item?.title}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box display='flex' flexDirection='column'>
                        <Typography sx={styles.metadata}>
                          {item?.metadata?.map(
                            (metadata: ComparsionMetadataProp, index: number) => {
                              if (metadata?.value) {
                                return (
                                  <React.Fragment key={metadata?.id}>
                                    <Tooltip title={`${metadata?.label} : ${metadata?.value}`}>
                                      <span
                                        style={{
                                          display: 'inline-block',
                                          marginRight: 8,
                                          ...metadata?.style
                                        }}>
                                        {metadata?.value}
                                      </span>
                                    </Tooltip>
                                    {index < item.metadata.length - 1 && (
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        sx={{ marginX: 1 }}
                                      />
                                    )}
                                  </React.Fragment>
                                );
                              }
                              return null;
                            }
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={styles.bottomDivider} />
                </React.Fragment>
              );
            })}
          </Box>
        </>
      </Box>
    </>
  );
};

export default React.memo(ComparsionSectionList);
