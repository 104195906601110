import makeRequest, { makeRequestWithCancel } from '../client';

/**
 *
 * Function gets all data for guidance module
 */
export const getGuidanceDocs = (filters: any, cancelToken: any) =>
  makeRequestWithCancel(`/mhra-guidance`, 'POST', filters, {}, cancelToken);

export const getGuidanceInsights = (filters: any) =>
  makeRequest('/mhra-guidance/insights', 'POST', filters);

export const getGuidanceDocumentsSignedURL = (payload: any) =>
  makeRequest('/pdf/resource', 'POST', payload);

export const getUpdatesApi = (id: any) => makeRequest(`/mhra-guidance/history/${id}`, 'GET');

export const getAllGuidanceDocuments = (filters: any) =>
  makeRequest('/mhra-guidance', 'POST', filters);
